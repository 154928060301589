import React from "react";
import { Box } from "@mui/material";

const OrderStatus = ({
    status
}) => {
	const backgroundColor = {
		"pending": "#FFA500",
		"confirmed": "#000000",
		"cancelled": "#FF0000",
		"refunded": "#00FF00",
		"PAID": '#0179CE'
	};

	return (
		<Box
			sx={{
				fontFamily: "Inter",
				fontWeight: 700,
				fontSize: 13,
				margin: 0,
				padding: '1px 5px',
				background: backgroundColor[status] || "#000",
				color: "#fff",
				height: "17px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				borderRadius: "3px",
				px: 1,
			}}
		>
			{status}
		</Box>
	);
};

export default OrderStatus;
