import React, { useEffect, useRef } from "react";
import { Box, Typography, TextField } from "@mui/material";

import styles from "./VrScreenTextInput.module.css";

const VrScreenTextInput = (props) => {
  const inputRef = useRef();

  let inputLabelStyle = {
    color: props?.labelColor ? props.labelColor : "#FFF",
    fontSize: 15,
    fontFamily: "BauhausLight",
  };

  useEffect(() => {
    if (inputRef.current) {
      if (props?.type == "tel") {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }
    }
  }, [props?.value]);

  return (
    <Box className={styles.mainView}>
      {props.icon ? <Box>{props.icon}</Box> : null}
      <Box ml={2} className={styles.textInput}>
        <TextField
          inputRef={inputRef}
          label={props.label}
          variant="standard"
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          type={props?.type ? props.type : "default"}
          sx={{
            "& .MuiInput-root": {
              "&:before, :after, :hover:not(.Mui-disabled):before": {
                borderBottom: "1px solid #FFF",
              },
            },
            "&.MuiTextField-root": {
              width: "100%",
            },
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              ...inputLabelStyle,
              "&.Mui-focused": {
                color: props?.labelColor ? props.labelColor : "#FFF",
              },
            },
          }}
          inputProps={{
            sx: {
              color: props?.inputColor ? props.inputColor : "#FFF",
              fontFamily: "BauhausMedium",
            },
          }}
        />
        <Typography className={styles.error}>{props?.error}</Typography>
      </Box>
    </Box>
  );
};

export default VrScreenTextInput;
