import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Lottie from "lottie-react";
import moment from "moment";

import ARWebAddress from "./ARWebAddress";

import "./../assets/css/custom.css";

import { ReactComponent as TickIcon } from "../assets/images/tick_green.svg";
import { ReactComponent as LogoIcon } from "../assets/images/logo_yellow.svg";
import OrderConfirmAnimation from "../assets/images/order_confirm.json";

import i18next from "../constants/i18n";
import { TABLE, DELIVERY } from "../constants";

const ARWebOrderConfirm = (props) => {
  let { restTemplate } = props;
  // if (props.orderType == DELIVERY) {
  return (
    <Box>
      <Box p={2} mx={1} mt={1} sx={styles.dataView}>
        <Box pt={2} sx={styles.topView}>
          <TickIcon width={70} height={70} />
          <Typography mt={2} sx={styles.thankyouText}>
            {i18next.t("Thank you!")}&lrm;
          </Typography>
          <Typography sx={styles.orderPlacedText}>
            Your order #{props.orderId.toString().substr(-5)} has been
            placed.&lrm;
          </Typography>
        </Box>
        {props.shippingAddress?.email ? (
          <Typography mt={3} sx={styles.sendEmailText}>
            We sent an email to {props.shippingAddress.email} with your order
            confirmation and bill.&lrm;
          </Typography>
        ) : null}
        <Typography mt={3} sx={styles.timeText}>
          {i18next.t("Time placed")}: {moment().format("DD/MM/Y HH:mm A")}
        </Typography>
      </Box>
      {props.orderType == TABLE ? null : (
        <Box p={2} mx={1} mt={2} sx={styles.dataView}>
          <Typography sx={styles.addressTitle}>
            {restTemplate == "temp1"
              ? i18next.t("Shipping")
              : i18next.t("Delivery")}
          </Typography>
          <ARWebAddress
            page="order_confirm"
            locale={props.locale}
            address={props.shippingAddress}
          />
          <Typography mt={3} sx={styles.addressTitle}>
            {i18next.t("Billing")}
          </Typography>
          <ARWebAddress
            page="order_confirm"
            locale={props.locale}
            address={{
              name: props.shippingAddress.name,
              email: props.shippingAddress.email,
              phone_code: props.shippingAddress.phoneCode,
              phone: props.shippingAddress.phone,
              addressTitle: props.shippingAddress.billingAddressTitle,
              address: props.shippingAddress.billingAddress,
            }}
          />
        </Box>
      )}
    </Box>
  );
  // } else {
  //   return (
  //     <Box pt={2} sx={styles.dataPickupView}>
  //       <Box mt={3}>
  //         <LogoIcon width={100} height={100} />
  //       </Box>
  //       <Typography px={2} mt={4} mb={8} sx={styles.descText}>
  //         {i18next.t("Preparing Your Order")}
  //       </Typography>
  //       <Box sx={styles.lottieView}>
  //         <Lottie animationData={OrderConfirmAnimation} loop={true} />
  //       </Box>
  //     </Box>
  //   );
  // }
};

export default ARWebOrderConfirm;

const styles = {
  dataView: {
    backgroundColor: "#FFF",
    borderRadius: "15px",
  },
  topView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // borderBottom: "1px solid #EEEFF0",
    // pb: 2.5,
  },
  thankyouText: {
    color: "#212529",
    fontSize: 22,
    fontFamily: "GilroyBlack",
    textAlign: "center",
  },
  orderPlacedText: {
    color: "#212529",
    fontSize: 16,
    fontFamily: "GilroyMedium",
    textAlign: "center",
  },
  sendEmailText: {
    color: "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
  timeText: {
    color: "#212529",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
  },
  addressTitle: {
    color: "#212529",
    fontSize: 15,
    fontFamily: "GilroyBlack",
  },
  titleText: {
    color: "#212529",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
  },
  dataPickupView: {
    backgroundColor: "#FFF",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  descText: {
    color: "#212529",
    fontSize: 50,
    lineHeight: "50px",
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  lottieView: {
    height: "40%",
    display: "flex",
    justifyContent: "center",
  },
};
