import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import AppNextBtn from "./AppNextBtn";
import AppNextBtn2 from "./AppNextBtn2";
import AppPreviousBtn from "./AppPreviousBtn";
import AppPreviousBtn2 from "./AppPreviousBtn2";
import ARWebAddSubMenuBtn from "./ARWebAddSubMenuBtn";

import "./../assets/css/custom.css";

import { CURRENCY_SYMBOL, MENU_TYPE, THEME_COLOR } from "../constants";

import { ReactComponent as MenuImg } from "../assets/images/file1.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close2.svg";
import { ReactComponent as RectangleIcon } from "../assets/images/rectangle.svg";
import { ReactComponent as InfoIcon } from "../assets/images/allergens_info.svg";
import { ReactComponent as ARLogoIcon } from "../assets/images/ar_logo.svg";
import { ReactComponent as ViewArIcon } from "../assets/images/view_ar.svg";
import { ReactComponent as UpArrowIcon } from "../assets/images/up_arrow_grey.svg";
import { ReactComponent as DownArrowIcon } from "../assets/images/down_arrow_grey.svg";
import { ReactComponent as NoteIcon } from "../assets/images/note.svg";
import { ReactComponent as RadioOffIcon } from "../assets/images/radio_off2.svg";
import { ReactComponent as RadioOnIcon } from "../assets/images/radio_on2.svg";
import ARWebAddNote from "./ARWebAddNote";
import {
  checkAllergies,
  checkNutritions,
  getRequiredModifierPrice,
  onModifierSelect,
  onSetDefaultModifierData,
} from "../constants/commonFunc";
import { THEME_COLOR2 } from "../containers/MenuList2";

const symbol = CURRENCY_SYMBOL;
const IMG_HEIGHT = window.innerHeight / 3.2;

let ALIGNITEMS = ["flex-start", "center", "flex-end"];

const ARWebMenuModal = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [cartCount, setCartCount] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [addNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [menuNote, setMenuNote] = useState("");
  const [addedOptions, setAddedOptions] = useState([]);
  const [addedModifiers, setAddedModifiers] = useState([]);

  let { data } = props;
  const dishNotes =
    data?.dishnote?.toUpperCase() == "NA" || data?.dishnote == "0"
      ? []
      : data?.dishnote
      ? data?.dishnote.split(",")
      : [];
  const currency = symbol?.[data?.price?.currency];
  const itemPrice = (
    data
      ? data?.price?.value
        ? data.price.value
        : getRequiredModifierPrice(data?.modifiers, props.menuList)
      : 0
  )?.toFixed(2);

  let model_exist = false;
  if (props.page == "main") {
    let menu_model = props.menuModal;
    if (menu_model?.status == 4) {
      model_exist = true;
    } else {
      model_exist = false;
    }
  }

  useEffect(() => {
    setModalVisible(props.visible);
    setCartCount(1);
  }, [props.visible]);

  useEffect(() => {
    const modifier = onSetDefaultModifierData(
      props.data?.modifiers,
      props?.menuList,
      "modal"
    );
    setAddedModifiers(modifier);
  }, [props.data]);

  const arModelClick = () => {
    props.arModelClick(data);
  };

  const addSubMenu = (type) => {
    let count = cartCount;
    if (type === "-" && cartCount > 1) {
      count = cartCount - 1;
      addedOptions.pop();
    }
    if (type === "+") {
      count = cartCount + 1;
    }
    setCartCount(count);
  };

  const onAddMenuItemToCart = () => {
    props.onAddMenuItemToCart(
      props.data,
      menuNote,
      addedOptions,
      addedModifiers,
      cartCount
    );
  };

  const onCloseModal = (e, reason) => {
    setModalVisible(false);
    setTimeout(() => {
      if (reason == "backdropClick" && props.page == "main") {
        if (model_exist) {
          props.onClose();
        }
      } else {
        props.onClose();
      }
    }, 250);
  };

  const onModifierClick = (items, menu_data) => {
    let data = [...addedModifiers];
    let value = onModifierSelect(data, items, menu_data);
    setAddedModifiers(value);
  };

  const AddToCartView =
    cartCount > 0 ? (
      <Box sx={styles.addToCartView} onClick={onAddMenuItemToCart}>
        <Typography sx={styles.addToCartText}>
          Add {cartCount} to Basket
        </Typography>
        <Box sx={styles.dotText} mx={0.5}></Box>
        <Typography sx={styles.addToCartText} dir={"ltr"}>
          {currency} {(data?.price.value * cartCount).toFixed(2)}
        </Typography>
      </Box>
    ) : null;

  return (
    <SwipeableDrawer
      className={"swipeable-drawer-modal"}
      sx={styles.modelDialogView}
      anchor={"bottom"}
      open={modalVisible}
      disableSwipeToOpen
      onClose={
        !(props.page == "menu" || model_exist) &&
        (props.prevImg || props.nextImg)
          ? () => null
          : onCloseModal
      }
      transitionDuration={{
        enter: 300,
        exit: 350,
      }}
    >
      <Box className={"device-width"} sx={styles.mainDialogView}>
        <Box
          sx={{
            ...styles.dataMainView,
            height:
              model_exist || props.page == "menu"
                ? window.innerHeight - (props.addToCartExist ? 195 : 100)
                : window.innerHeight - (props.addToCartExist ? 270 : 175),
          }}
        >
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              height: { xs: IMG_HEIGHT, md: 300 },
            }}
          >
            {data?.images.length ? (
              <Box sx={styles.menuImage}>
                <img
                  src={data?.images[0]}
                  width={"100%"}
                  height={"100%"}
                  style={styles.menuImageStyle}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  ...styles.menuImage,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MenuImg width={"40%"} height={"40%"} />
              </Box>
            )}
            <Box className="bg-black-shade" style={styles.bgShadeImage} />
            {props.page == "menu" &&
            data?.modelId != null &&
            data?.modelId != "" ? (
              <a
                slot="ar-button"
                onClick={arModelClick}
                style={{
                  ...styles.arLogo,
                  transform: props.locale == "ar" ? "scaleX(-1)" : "unset",
                  right: props.locale == "ar" ? "unset" : 14,
                  left: props.locale == "ar" ? 14 : "unset",
                }}
              >
                <ViewArIcon width={44} height={44} />
              </a>
            ) : null}
            {checkAllergies(data?.allergies) ||
            checkNutritions(data?.nutritions) ? (
              <Box
                sx={{
                  ...styles.infoBtn,
                  top:
                    props.page == "menu" &&
                    data?.modelId != null &&
                    data?.modelId != ""
                      ? 64
                      : 14,
                  transform: props.locale == "ar" ? "scaleX(-1)" : "unset",
                  right: props.locale == "ar" ? "unset" : 14,
                  left: props.locale == "ar" ? 14 : "unset",
                }}
                onClick={() => props.onAllergensClick()}
              >
                <InfoIcon width={44} height={44} />
              </Box>
            ) : null}

            {!(props.page == "menu" || model_exist) &&
            (props.prevImg || props.nextImg) ? null : (
              <Box
                sx={[
                  styles.closeBtn,
                  {
                    right: props.locale == "ar" ? 14 : "unset",
                    left: props.locale == "ar" ? "unset" : 14,
                  },
                ]}
                style={{ cursor: "pointer" }}
                onClick={props.onClose}
              >
                <CloseIcon width={44} height={44} />
              </Box>
            )}
            <Box sx={styles.titlePriceView}>
              <Box>
                <Box sx={styles.titleView}>
                  <Typography
                    className="first-char-capital"
                    sx={styles.titleText}
                  >
                    {data?.name}
                  </Typography>
                </Box>
                <Box mt={1} sx={styles.kcalMainView}>
                  {data?.calories ? (
                    <Box sx={styles.kcalTextView} dir={"ltr"}>
                      <Typography sx={styles.kcalText}>
                        {data?.calories ? data?.calories : 0} kcal
                      </Typography>
                    </Box>
                  ) : null}
                  {data?.extras?.menuType ? (
                    <>
                      {data?.calories ? (
                        <Typography sx={styles.lineText}> | </Typography>
                      ) : null}
                      <Box sx={styles.foodTypeView}>
                        {
                          MENU_TYPE.filter(
                            (z) => z.title == data?.extras?.menuType
                          )?.[0]?.["icon"]
                        }
                        <Typography
                          sx={[
                            styles.foodTypeText,
                            { pr: props.locale == "ar" ? 1 : "unset" },
                          ]}
                        >
                          {data?.extras?.menuType}
                        </Typography>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Box>
              <Box sx={styles.menuPriceView} dir={"ltr"}>
                <Typography sx={styles.menuPrice}>
                  {currency} {itemPrice}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.dataView}>
            <Box sx={styles.descSubView}>
              {dishNotes.slice(0, 3).map((x, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      ...styles.typeMainView,
                      justifyContent: ALIGNITEMS[i],
                    }}
                  >
                    <Box sx={styles.typeView}>
                      <Box
                        sx={{
                          width: {
                            xs: window.innerWidth / 3.5,
                            sm: 120,
                            md: 120,
                            transform:
                              props.locale == "ar" ? "scaleX(-1)" : "unset",
                          },
                        }}
                      >
                        <RectangleIcon width={"100%"} height={"100%"} />
                      </Box>
                      <Box sx={styles.typeTextView}>
                        <Typography sx={styles.typeText}>{x}</Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {data?.description ? (
              <Box sx={styles.descTextView}>
                <Typography
                  sx={styles.descText}
                  className={showFullDesc ? "" : "ar-menu-desc-ellipse"}
                >
                  {data?.description}
                </Typography>
                <Box sx={styles.upDownArrow} mt={1}>
                  <Box
                    sx={styles.upDownArrowIcon}
                    onClick={() => setShowFullDesc(!showFullDesc)}
                  >
                    {showFullDesc ? (
                      <UpArrowIcon width={16} height={16} />
                    ) : (
                      <DownArrowIcon width={16} height={16} />
                    )}
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box>
              {(props.data?.modifiers ?? []).map((item, i) => {
                return (
                  <Box key={i} sx={styles.optionsMainView} px={2}>
                    <Typography sx={styles.optionsText}>
                      {item.header}
                    </Typography>
                    <Typography sx={styles.optionsChoseText}>
                      {item?.multiSelection
                        ? "Please choose any"
                        : "Please choose one"}
                    </Typography>
                    {item.items.map((x, i2) => {
                      let menu_data = null;
                      let menu_name = "";
                      let menu_price = "";
                      let menu_currency = "";
                      let find_index = props.menuList.findIndex(
                        (y) => y?._id == x
                      );
                      if (find_index >= 0) {
                        menu_data = props.menuList?.[find_index];
                        menu_name = menu_data?.name;
                        menu_currency = menu_data?.price?.currency;
                        menu_price = menu_data?.price?.value;
                      }

                      if(!menu_data) {
                        return null
                      }
                      return (
                        <Box key={"i" + i2} sx={styles.optionItemView}>
                          <Box sx={styles.optionItemTextView}>
                            <Typography sx={styles.optionItemText}>
                              {menu_name}
                            </Typography>
                          </Box>
                          {menu_price ? (
                            <Typography sx={styles.optionItemText} mr={1}>
                              {CURRENCY_SYMBOL?.[menu_currency]}
                              {menu_price?.toFixed(2)}
                            </Typography>
                          ) : null}
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => onModifierClick(item, menu_data)}
                          >
                            {addedModifiers?.some(
                              (y) =>
                                y._idMenuItem === x &&
                                y._idModifier === item._id
                            ) ? (
                              <RadioOnIcon width={20} height={20} />
                            ) : (
                              <RadioOffIcon width={20} height={20} />
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
            <Box sx={styles.addNoteMainView} px={2}>
              <Box sx={styles.addNoteSubView}>
                <Box
                  sx={{
                    ...styles.addNoteView,
                    alignItems: menuNote ? "flex-start" : "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 18,
                      transform: props.locale == "ar" ? "scaleX(-1)" : "unset",
                      pr: props.locale == "ar" ? 1 : "unset",
                    }}
                  >
                    <NoteIcon width={15} height={15} />
                  </Box>
                  {menuNote ? (
                    <Typography mt={-0.2} sx={styles.noteText}>
                      Notes: {menuNote}
                    </Typography>
                  ) : (
                    <Typography
                      mt={-0.2}
                      sx={styles.specialRequestText}
                      dir={"ltr"}
                    >
                      Any special requests?
                    </Typography>
                  )}
                </Box>
                {menuNote ? null : (
                  <Box onClick={() => setAddNoteModalVisible(true)}>
                    <Typography sx={styles.addNoteText}>Add Note</Typography>
                  </Box>
                )}
              </Box>
              {menuNote ? (
                <Box onClick={() => setAddNoteModalVisible(true)}>
                  <Typography
                    mt={0.2}
                    sx={{
                      ...styles.addNoteText,
                      textAlign: props.locale == "ar" ? "unset" : "right",
                    }}
                    dir={props.locale == "ar" ? "ltr" : "rtl"}
                  >
                    Edit
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {props.addToCartExist ? (
          <Box
            mt={1}
            mb={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ARWebAddSubMenuBtn
              count={cartCount}
              minWidth={130}
              circleSize={32}
              addSubFontSize={20}
              onAddSubMenu={addSubMenu}
            />
          </Box>
        ) : null}
        {props.page == "menu" || model_exist ? (
          props.addToCartExist ? (
            <Box sx={styles.menuBottomView}>{AddToCartView}</Box>
          ) : null
        ) : props.page == "main" ? (
          <>
            {props.addToCartExist ? (
              <Grid
                container
                item
                mt={2}
                xs={12}
                px={2}
                mb={2}
                direction="row"
                alignItems={"center"}
                justifyContent={"center"}
              >
                {AddToCartView}
              </Grid>
            ) : null}
            <Grid
              container
              item
              mt={2}
              xs={12}
              px={2}
              mb={2}
              direction="row"
              style={styles.bottomView}
            >
              <Grid
                container
                item
                xs={4}
                direction="row"
                sx={styles.prevMainView}
              >
                {props.prevImg ? (
                  props.template == "temp2" ? (
                    <AppPreviousBtn2
                      locale={props.locale}
                      image={props.prevImg}
                      isCircle={true}
                      onPrevBtnClick={props.onPrevBtnClick}
                    />
                  ) : (
                    <AppPreviousBtn
                      locale={props.locale}
                      image={props.prevImg}
                      onPrevBtnClick={props.onPrevBtnClick}
                    />
                  )
                ) : null}
              </Grid>
              <Grid
                container
                item
                xs={4}
                direction="row"
                sx={styles.nextMainView}
              >
                {props.nextImg ? (
                  props.template === "temp2" ? (
                    <AppNextBtn2
                      locale={props.locale}
                      image={props.nextImg}
                      isCircle={true}
                      onNextBtnClick={props.onNextBtnClick}
                    />
                  ) : (
                    <AppNextBtn
                      locale={props.locale}
                      image={props.nextImg}
                      onNextBtnClick={props.onNextBtnClick}
                    />
                  )
                ) : null}
              </Grid>
            </Grid>
          </>
        ) : null}
        <ARWebAddNote
          note={menuNote}
          modalVisible={addNoteModalVisible}
          onClose={(val) => {
            setMenuNote(val);
            setAddNoteModalVisible(false);
          }}
        />
      </Box>
    </SwipeableDrawer>
  );
};

export default ARWebMenuModal;

const styles = {
  modelDialogView: {
    "& .MuiPaper-root": {
      overflow: "hidden",
    },
  },
  mainDialogView: {
    backgroundColor: "#FFF",
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
    overflow: "hidden",
  },
  bgShadeImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  dataMainView: {
    position: "relative",
    overflow: "auto",
  },
  dataView: {
    pt: 1.8,
    pb: 1,
  },
  menuImage: {
    backgroundColor: "#EEE",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  menuImageStyle: {
    objectFit: "cover",
  },
  infoBtn: {
    position: "absolute",
    cursor: "pointer",
  },
  closeBtn: {
    position: "absolute",
    cursor: "pointer",
    top: 14,
  },
  titlePriceView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 14,
    right: 14,
  },
  titleView: {
    width: "100%",
    alignSelf: "center",
  },
  titleText: {
    color: "#FFF",
    fontSize: 19,
    fontFamily: "GilroySemiBold",
    lineHeight: "1.2",
    wordBreak: "break-word",
    textAlign: "left",
  },
  menuPriceView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    my: 1,
    ml: 1,
  },
  menuPrice: {
    backgroundColor: THEME_COLOR,
    fontSize: 19,
    fontFamily: "GilroyBold",
    textAlign: "center",
    minWidth: 56,
    height: 28,
    borderRadius: 20,
    px: "10px",
  },
  kcalMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mb: 2,
  },
  kcalTextView: {},
  kcalText: {
    color: "#FFF",
    fontSize: 13,
    fontFamily: "GilroyRegular",
  },
  lineText: {
    color: "#EEE",
    fontSize: 13,
    fontFamily: "GilroySemiBold",
    mx: 1,
  },
  foodTypeView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  foodTypeText: {
    color: "#FFF",
    fontSize: 13,
    fontFamily: "GilroyRegular",
    ml: 0.7,
  },
  descTextView: {
    borderBottom: "4px solid #F4F4F4",
    pb: 1.5,
    px: 2,
  },
  descText: {
    color: "#555",
    fontSize: 13,
    fontFamily: "GilroySemiBold",
    lineHeight: "1.4",
    wordBreak: "break-word",
  },
  upDownArrow: {
    display: "flex",
    justifyContent: "flex-end",
  },
  upDownArrowIcon: {
    cursor: "pointer",
    marginTop: -1,
  },
  descSubView: {
    display: "flex",
    flexDirection: "row",
    px: 2,
  },
  arLogo: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    zIndex: 3,
    marginTop: 14,
  },
  arViewDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: 0.5,
  },
  arViewText1: {
    color: "#FFF",
    fontSize: 5,
    fontFamily: "GilroySemiBold",
  },
  arViewText2: {
    color: "#ffe342",
    fontSize: 5,
    fontFamily: "GilroySemiBold",
    marginLeft: 0.2,
  },
  typeMainView: {
    display: "flex",
    width: { xs: "33%", md: "fit-content", sm: "fit-content" },
    mb: 1.5,
  },
  typeView: {
    width: "fit-content",
    position: "relative",
  },
  typeTextView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 10,
    left: 0,
    right: 0,
  },
  typeText: {
    color: "#000",
    fontSize: 11,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  allergensMainBtn: {
    display: "flex",
    justifyContent: "center",
  },
  optionsMainView: {
    borderBottom: "4px solid #F4F4F4",
    pt: 1,
    pb: 2,
  },
  optionsText: {
    color: "#000",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
  },
  optionsChoseText: {
    color: "#828282",
    fontSize: 11,
    fontFamily: "GilroySemiBold",
  },
  optionItemView: {
    display: "flex",
    flexDirection: "row",
    pt: 1,
  },
  optionItemTextView: {
    display: "flex",
    flex: 1,
  },
  optionItemText: {
    color: "#000",
    fontSize: 14,
    fontFamily: "GilroySemiBold",
    textTransform: "capitalize",
  },
  addNoteMainView: {
    borderBottom: "4px solid #F4F4F4",
    py: 1.5,
  },
  addNoteSubView: {
    display: "flex",
    flexDirection: "row",
  },
  addNoteView: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  specialRequestText: {
    color: "#000",
    fontSize: 13,
    fontFamily: "GilroyBold",
    ml: 1.5,
  },
  noteText: {
    color: "#000000",
    fontSize: 13,
    fontFamily: "GilroyBold",
    mx: 1.5,
  },
  addNoteText: {
    color: "#E4B700",
    fontSize: 13,
    fontFamily: "GilroyBold",
  },
  menuBottomView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(219,219,219,0.2)",
    py: 1,
    px: 2,
  },
  addToCartView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "#000",
    height: 40,
    borderRadius: 44,
    minWidth: 180,
    px: 3,
  },
  addToCartText: {
    color: THEME_COLOR,
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
  dotText: {
    backgroundColor: THEME_COLOR,
    width: 4,
    height: 4,
    borderRadius: 2,
  },
  closeView: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    my: 2,
  },
  bottomView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  prevMainView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  nextMainView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
