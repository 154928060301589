import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

import {THEME_COLOR} from "../constants";

import {ReactComponent as CloseIcon} from "../assets/images/close_white.svg";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const textInputCss = {
  '& .MuiInputBase-root': {
    pt: 1.5,
    px: 0,
    backgroundColor: '#FFF !important',
    '&:before, :after, :hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
};

const ARWebAddNote = (props) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(props.note ? props.note : '');
  }, [props.modalVisible]);

  const onChangeNote = (val) => {
    setNote(val);
    // props.onSubmitNote({...item, note: val})
  }

  let item = props.data;

  return (
    <SwipeableDrawer
      sx={styles.modelDialogView}
      anchor={'bottom'}
      open={props.modalVisible}
      onClose={() => props.onClose(note)}
      transitionDuration={{
        enter: 300,
        exit: 350
      }}>
      <Box sx={styles.mainDialogView} dir={'ltr'}>
        <Box sx={styles.textInputView}>
          <Typography sx={styles.requestText}>Special requests?</Typography>
          <TextField
            required
            focused
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            value={note}
            variant="filled"
            sx={textInputCss}
            inputProps={{
              autoComplete: 'none',
              sx: {...styles.inputText}
            }}
            onChange={e => onChangeNote(e.target.value)}
          />
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}

export default ARWebAddNote;

const styles = {
  modelDialogView: {
    "& .MuiPaper-root": {
      overflow: 'hidden',
    },
  },
  mainDialogView: {
    backgroundColor: '#FFF',
    p: 1.5
  },
  textInputView: {
    backgroundColor: '#FFF',
    border: '1px solid #FFCC00',
    borderRadius: '7px',
    p: 1.5
  },
  requestText: {
    fontSize: 17,
    fontFamily: 'GilroyBold',
    color: '#000',
  },
  inputText: {
    fontSize: 15,
    fontFamily: 'GilroySemiBold',
    color: '#000',
  },
};
