import React, { createContext, useState, useContext } from "react";

const OrdersContext = createContext();

export const OrdersProvider = ({ children }) => {
	const [confirmedOrdersCtx, setConfirmedOrdersCtx] = useState([]);
	const [restaurantIdCtx, setRestaurantIdCtx] = useState(null);
	const [tableNumberCtx, setTableNumberCtx] = useState(null);
	const [subTotalCtx, setSubTotalCtx] = useState(null);
	const [discountCtx, setDiscountCtx] = useState(null);
	const [taxValueCtx, setTaxValueCtx] = useState(null);
	const [tipValueCtx, setTipValueCtx] = useState(null);
	const [totalCartPriceCtx, setTotalCartPriceCtx] = useState(null);

	return (
		<OrdersContext.Provider
			value={{
				confirmedOrdersCtx,
				setConfirmedOrdersCtx,
				restaurantIdCtx,
				setRestaurantIdCtx,
				tableNumberCtx,
				setTableNumberCtx,
                subTotalCtx,
                setSubTotalCtx,
                discountCtx,
                setDiscountCtx,
                taxValueCtx,
                setTaxValueCtx,
                tipValueCtx,
                setTipValueCtx,
                totalCartPriceCtx,
                setTotalCartPriceCtx,
			}}
		>
			{children}
		</OrdersContext.Provider>
	);
};

export const useOrders = () => useContext(OrdersContext);
