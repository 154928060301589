import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";

import ARWebCartTextInput from "./ARWebCartTextInput";
import AlertMsg from "./AlertMsg";
import Loader from "./Loader";

import "./../assets/css/custom.css";

import i18next from "../constants/i18n";
import { THEME_COLOR as THEMECOLOR } from "../containers/MenuList2";

import { ReactComponent as LocationPointerIcon } from "../assets/images/location_pointer.svg";

let InputViewStyle = {
  backgroundColor: "#FCFCFD",
  border: "1px solid #CFD8E8",
  borderRadius: "8px",
};

const ARWebAddressForm = (props) => {
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [isLoading, setIsLoading] = useState(false);

  let {
    addressTitle,
    address,
    pincode,
    city,
    country,
    countryList,
    cityList,
    pincodeList,
    restTemplate,
  } = props;

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      setMsgAlert({
        open: true,
        message: "Geolocation is not supported by your browser",
        msgType: "error",
      });
    } else {
      setIsLoading(true);
      console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          getUserLocation(position.coords);
        },
        () => {
          setIsLoading(false);
          console.log("Unable to retrieve your location");
          setMsgAlert({
            open: true,
            message: "Unable to retrieve your location",
            msgType: "error",
          });
        }
      );
    }
  };

  const getUserLocation = (position) => {
    let locationResponse = "";
    let locationAddress = "";
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        position.latitude +
        "," +
        position.longitude +
        "&key=" +
        process.env.REACT_APP_MAP_API_KEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let pin_code_val = "";
        let city_val = "";
        let country_val = "";
        console.log(responseJson);
        locationResponse = responseJson.results[0].address_components;
        locationAddress =
          (locationResponse.length >= 1 ? locationResponse[0].long_name : "") +
          (locationResponse.length >= 2
            ? ", " + locationResponse[1].long_name
            : "") +
          (locationResponse.length >= 3
            ? ", " + locationResponse[2].long_name
            : "");

        locationResponse.map((y, i) => {
          if (y.types.indexOf("postal_code") > -1) {
            pin_code_val = y.long_name;
          } else if (y.types.indexOf("country") > -1) {
            country_val = y.long_name;
          }

          if (city_val == "") {
            if (y.types.indexOf("administrative_area_level_7") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("administrative_area_level_6") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("administrative_area_level_5") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("administrative_area_level_4") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("administrative_area_level_3") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("administrative_area_level_2") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("administrative_area_level_1") > -1) {
              city_val = y.long_name;
            }
          }

          if (city_val == "") {
            if (y.types.indexOf("sublocality_level_5") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("sublocality_level_4") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("sublocality_level_3") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("sublocality_level_2") > -1) {
              city_val = y.long_name;
            }
            if (y.types.indexOf("sublocality_level_1") > -1) {
              city_val = y.long_name;
            }
          }

          if (y.types.indexOf("locality") > -1) {
            city_val = y.long_name;
          }
        });

        if (pin_code_val != "") {
          props.setPincodeList([
            pin_code_val,
            ...props.pincodeList.filter((x) => x != pin_code_val),
          ]);
        }
        if (city_val != "") {
          props.setCityList([
            city_val,
            ...props.cityList.filter((x) => x != city_val),
          ]);
        }
        if (country_val != "") {
          props.setCountryList([
            country_val,
            ...props.countryList.filter((x) => x != country_val),
          ]);
        }
        props.setPincode(pin_code_val);
        props.setCountry(country_val);
        props.setCity(city_val);
        props.setAddress(locationAddress);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setMsgAlert({
          open: true,
          message: "Location fetch failed",
          msgType: "error",
        });
      });
  };

  return (
    <Box>
      {isLoading ? (
        <Box sx={styles.loaderView}>
          <Loader height={{ height: window.innerHeight }} />
        </Box>
      ) : null}
      <ARWebCartTextInput
        page={"add_address"}
        locale={props.locale}
        value={addressTitle}
        setValue={props.setAddressTitle}
        label={i18next.t("Address Title (Optional)")}
        required={false}
        inputViewStyle={InputViewStyle}
      />
      {/*<Typography sx={styles.descText}>*/}
      {/*  {i18next.t('For estimating if the place is opened or closed on the weekends.')}*/}
      {/*</Typography>*/}

      <Typography mt={2} sx={styles.addressText}>
        {i18next.t("Address")}*&lrm;
      </Typography>
      <Box sx={styles.addressPointerView}>
        <Box sx={styles.addressPointerSubView}>
          <Input
            value={address}
            required
            onChange={(e) => props.setAddress(e.target.value)}
            disableUnderline
            inputProps={{
              autoComplete: "none",
              sx: styles.descText,
            }}
          />
        </Box>
        <Box
          pl={props.locale == "ar" ? "unset" : 2}
          pr={props.locale == "ar" ? 2 : "unset"}
          onClick={getCurrentLocation}
          sx={{
            path: {
              stroke: restTemplate == "temp1" ? "#C1A700" : THEMECOLOR,
            },
          }}
        >
          <LocationPointerIcon width={25} height={25} />
        </Box>
      </Box>
      <Box mb={2} sx={styles.streetAddressView}>
        <Box sx={styles.streetAddressAddView}>
          <Typography
            sx={{
              ...styles.streetAddressAddText,
              color: restTemplate == "temp1" ? "#C1A700" : THEMECOLOR,
            }}
          >
            +
          </Typography>
        </Box>
        <Typography
          ml={props.locale == "ar" ? "unset" : 1}
          mr={props.locale == "ar" ? 1 : "unset"}
          sx={{
            ...styles.streetAddressText,
            color: restTemplate == "temp1" ? "#C1A700" : THEMECOLOR,
          }}
        >
          {i18next.t("Street Address 2 (Optional)")}
        </Typography>
      </Box>

      <Typography mt={2} sx={styles.selectText}>
        {i18next.t("Pin Code")}
      </Typography>
      <FormControl variant="filled" sx={styles.selectMainView}>
        <Select
          value={pincode}
          onChange={(event) => props.setPincode(event.target.value)}
          sx={styles.selectView}
        >
          {pincodeList.map((item, index) => (
            <MenuItem key={index} value={item} sx={styles.selectValue}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography mt={2} sx={styles.selectText}>
        {i18next.t("City")}*&lrm;
      </Typography>
      <FormControl variant="filled" sx={styles.selectMainView}>
        <Select
          required
          value={city}
          onChange={(event) => props.setCity(event.target.value)}
          sx={styles.selectView}
        >
          {cityList.map((item, index) => (
            <MenuItem key={index} value={item} sx={styles.selectValue}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography mt={2} sx={styles.selectText}>
        {i18next.t("Country")}*&lrm;
      </Typography>
      <FormControl variant="filled" sx={styles.selectMainView}>
        <Select
          required
          value={country}
          onChange={(event) => props.setCountry(event.target.value)}
          sx={styles.selectView}
        >
          {countryList.map((item, index) => (
            <MenuItem key={index} value={item} sx={styles.selectValue}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Box>
  );
};

export default ARWebAddressForm;

const styles = {
  loaderView: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  descText: {
    color: "#000",
    fontSize: 12,
    fontFamily: "GilroyMedium",
  },
  addressPointerView: {
    backgroundColor: "#FCFCFD",
    border: "1px solid #CFD8E8",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    py: 1.3,
    px: 1.5,
    mt: 0.5,
    mb: 1,
  },
  addressPointerSubView: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  addressText: {
    color: "#000000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
  streetAddressView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  streetAddressAddView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FAF4CB",
    width: "16px",
    height: "16px",
    borderRadius: "5px",
  },
  streetAddressAddText: {
    color: "#C1A700",
    fontSize: 20,
    fontFamily: "GilroyMedium",
  },
  streetAddressText: {
    color: "#C1A700",
    fontSize: 14,
    fontFamily: "GilroyMedium",
  },
  selectMainView: {
    width: "100%",
    mt: 0.5,
    mb: 1,
  },
  selectView: {
    backgroundColor: "#FCFCFD",
    border: "1px solid #CFD8E8",
    borderRadius: "8px",
    color: "#000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
    height: 50,
    py: 1.3,
    px: 0.5,
    "&.MuiInputBase-root": {
      "&:hover": {
        backgroundColor: "#FCFCFD",
      },
      "&.Mui-focused": {
        backgroundColor: "#FCFCFD",
      },
      "&.Mui-disabled": {
        backgroundColor: "#FCFCFD",
      },
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: "0px !important",
      },
      "& .MuiSelect-select": {
        backgroundColor: "#FCFCFD",
        py: 0,
      },
    },
  },
  selectText: {
    color: "#000000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
  selectValue: {
    color: "#868E96",
    fontSize: 15,
    fontFamily: "GilroyMedium",
    textTransform: "capitalize",
  },
};
