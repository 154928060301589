import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./../assets/css/custom.css";

import {THEME_COLOR3} from "../constants";

const theme = createTheme({
  palette: {
    gray: {
      main: "#868E96",
    },
  },
});

const ARWebCartTextInput = (props) => {
  return (
    <Box>
      {props?.label ? (
        <Box sx={[styles.labelView, {justifyContent: props.locale == 'ar' ? 'flex-end' : 'unset'}]} dir={'ltr'}>
          <Typography mr={1} sx={styles.labelStyle}>{props.label}</Typography>
          {props?.labelIcon ? props.labelIcon : null}
        </Box>
      ) : null}
      <Box sx={{ ...styles.textInputView, ...props?.inputViewStyle }}>
        <ThemeProvider theme={theme}>
          <TextField
            required={props?.required ? true : false}
            fullWidth
            label={props?.placeholder ? props?.placeholder : null}
            name={props?.name ? props.name : Math.random()}
            type={props?.type ? props.type : "default"}
            color="gray"
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
            sx={{
              "& .MuiInput-root": {
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
              },
            }}
            InputLabelProps={{
              style: { color: THEME_COLOR3 },
              sx: { ...styles.inputLabelStyle, ...props?.inputLabelStyle },
            }}
            inputProps={{
              autoComplete: "none",
              sx: styles.textInputValue,
            }}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default ARWebCartTextInput;

const styles = {
  textInputView: {
    backgroundColor: "#FFF",
    py: 1,
  },
  labelView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mb: 1,
  },
  labelStyle: {
    color: "#000000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
  inputLabelStyle: {
    color: "#868E96",
    fontSize: 15,
    fontFamily: "GilroyMedium",
    px: 1.5,
  },
  textInputValue: {
    color: "#000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
    px: 1.5,
  },
};
