import moment from "moment";
import { MENU_TYPE2 } from "./index";

export const checkRestIsClosed = (openHrs) => {
  const format = "HH:mm";
  const day = moment().format("dddd").toLowerCase();
  const time_data = openHrs?.[day];

  if (time_data?.isOpen && time_data?.timings.length) {
    const { from, to } = time_data.timings[0];
    const fromTime = moment(from, format);
    const toTime = moment(to, format);
    const todayTime = moment();

    return !todayTime.isBetween(fromTime, toTime, null, "[]");
  }

  return true;
};

export const checkAllergies = (allergies) => {
  return !(
    allergies?.toUpperCase() == "NA" ||
    allergies == "0" ||
    allergies == null ||
    allergies == ""
  );
};

export const checkNutritions = (nutritions) => {
  return !(
    nutritions?.toUpperCase() == "NA" ||
    nutritions == "0" ||
    nutritions == null ||
    nutritions == ""
  );
};

export const onModifierSelect = (data, items, menu) => {
  const opt = menu._id;
  const item = items.items;

  let menu_data = {
    _idModifier: items._id,
    _idMenuItem: opt,
    name: menu?.name,
    price: menu?.price,
  };
  let existItem = item.filter((x) =>
    data.some((y) => y._idMenuItem === x && y._idModifier === items._id)
  );

  if (existItem.length && !items?.multiSelection) {
    if (!existItem.includes(opt)) {
      data = data.filter(
        (x) => !existItem.includes(x._idMenuItem) && x._idModifier !== items._id
      );
    }
  }

  if (data.some((y) => y._idMenuItem === opt && y._idModifier === items._id)) {
    let filter_data = data.filter(
      (x) => !(x._idMenuItem === opt && x._idModifier === items._id)
    );
    if (
      !items.required ||
      (items.multiSelection &&
        filter_data.filter((x) => x._idModifier === items._id).length)
    ) {
      data = filter_data;
    }
  } else {
    data = [...data, menu_data];
  }

  return data;
};

export const onSetDefaultModifierData = (modifierList, menuList, page) => {
  let modifiers = [];
  const enabledMenuListIds = menuList
    .filter((z) => checkMenuAvailability(z?.extras?.availability))
    .map((z) => z?._id);
  modifierList?.forEach((x) => {
    const menuItems = x.items.filter((z) => enabledMenuListIds?.includes(z));
    let defaultMod = x?.defaultSelection ?? "";

    if ((x.required && menuItems.length) || (defaultMod && page === "modal")) {
      let menuData = menuList.find((y) => y?._id === defaultMod) ?? null;

      if (!menuData && menuItems.length) {
        defaultMod = menuItems[0];
        menuData = menuList.find((y) => y?._id === defaultMod) ?? null;
      }

      if (menuData) {
        const menuName = menuData?.name;
        const menuPrice = menuData?.price;

        modifiers.push({
          _idModifier: x._id,
          _idMenuItem: defaultMod,
          name: menuName,
          price: menuPrice,
        });
      }
    }
  });
  return modifiers;
};

export const getModifierTotalPrice = (modifiers, modList) => {
  let menuTotal = 0;
  modifiers
    ?.filter((item) => modList?.map((x) => x?._idModifier)?.includes(item))
    ?.map((item) => {
      const objMax = modList
        ?.filter((item1) => item == item1?._idModifier)
        ?.reduce(
          (max, curren) =>
            max?.price?.value > curren?.price?.value ? max : curren,
          null
        );
      menuTotal += objMax?.price?.value ?? 0;
    });
  modList
    ?.filter((item) => !modifiers.includes(item?._idModifier))
    ?.map((item) => {
      menuTotal += item?.price?.value ?? 0;
    });
  return menuTotal;
};

export const getCartItemsWithTotalPrice = (order, menuList) => {
  const qty = order?.quantity ?? order?.count;

  const selectedModifiers =
    order?.selectedModifiers ??
    order?.modifiers.map((modifier) => {
      const menuItem = menuList.find(
        (menu) => menu?._id === modifier?._idMenuItem
      );
      const menuName = menuItem ? menuItem.name : "";
      return { ...modifier, name: menuName };
    });

  let modifiers =
    order?.selectedModifiers && order?.modifiers ? order?.modifiers : [];
  if (modifiers?.length === 0) {
    modifiers =
      menuList.find((x) => x?._id == (order?._idMenuItem ?? order?._id))
        ?.modifiers ?? [];
  }

  const modifierList = modifiers
    ?.filter((x) => !x.addUpPrices)
    ?.map((x) => x?._id);

  const itemTotal =
    (order.price.value +
      getModifierTotalPrice(modifierList, selectedModifiers)) *
    (qty ? qty : 1);

  return order?._idMenuItem
    ? {
        _id: order?._idMenuItem,
        name: order?.menuItemName,
        price: order?.price,
        note: order?.notes,
        count: order?.quantity,
        modifiers: modifiers,
        selectedModifiers: selectedModifiers,
        totalPrice: itemTotal,
      }
    : {
        ...order,
        totalPrice: itemTotal,
      };
};

export const getOrdersWithTotalPrice = (cartItemsList, menuList) => {
  let cartItems = [];
  let total = 0;
  cartItemsList?.forEach((order) => {
    const data = getCartItemsWithTotalPrice(order, menuList);
    total += data?.totalPrice ?? 0;
    cartItems.push(data);
  });
  return { cartItems, totalPrice: total };
};

export const getRequiredModifierPrice = (modifiers, menuList) => {
  return (
    menuList.find(
      (menu) =>
        menu?._id ===
        modifiers.find((item) => item?.required && item?.items?.length)
          ?.items?.[0]
    )?.price?.value ?? 0
  );
};

export const filterMenuType = (menuList) => {
  let menuTypeList = [];
  if (menuList.length) {
    menuList.forEach((item) => {
      if (
        item.extras?.menuType &&
        !menuTypeList.includes(item.extras?.menuType)
      ) {
        menuTypeList.push(item.extras?.menuType);
      }
    });

    const menuTypes = Object.keys(MENU_TYPE2);
    menuTypeList = [
      ...menuTypes.filter((x) => menuTypeList.includes(x)),
      ...menuTypeList.filter((x) => !menuTypes.includes(x) && x !== "None"),
    ];
  }
  return menuTypeList;
};

export const addMenuItemToCart = (
  currentRestId,
  savedRestId,
  cartItems,
  count,
  item,
  modifiers,
  options,
  note
) => {
  let list =
    currentRestId === savedRestId
      ? cartItems.filter((x) => x._idRestaurant === savedRestId)
      : [];
  if (count === 0) {
    list = list.filter(
      (x) =>
        !(
          x._id === item._id &&
          checkModifierAddedToCart(x?.selectedModifiers, modifiers)
        )
    );
  } else {
    const findIndex = list.findIndex(
      (x) =>
        x._id === item._id &&
        checkModifierAddedToCart(x?.selectedModifiers, modifiers)
    );
    if (findIndex >= 0) {
      list[findIndex]["count"] = list[findIndex]["count"] + count;
      list[findIndex]["note"] = note;
      list[findIndex]["selectedOptions"] = options;
      list[findIndex]["selectedModifiers"] = modifiers;
    } else {
      list = [
        ...list,
        {
          ...item,
          count: count,
          note: note,
          selectedOptions: options,
          selectedModifiers: modifiers,
        },
      ];
    }
  }
  return list;
};

export const checkModifierAddedToCart = (cartModifiers, newModifiers) => {
  return (
    JSON.stringify(
      cartModifiers?.map((mod) => {
        return { _idModifier: mod?._idModifier, _idMenuItem: mod?._idMenuItem };
      })
    ) ===
    JSON.stringify(
      newModifiers?.map((mod) => {
        return { _idModifier: mod?._idModifier, _idMenuItem: mod?._idMenuItem };
      })
    )
  );
};

export const checkMenuAvailability = (availability) => {
  const { start = null, end = null, days = [] } = availability || {};

  if (typeof availability === "undefined") {
    return true;
  }

  const currentDay = moment().format("dddd").toLowerCase();
  if (days.includes(currentDay)) {
    if (start === null && end === null) {
      return true;
    }

    const format = "H:mm";
    const fromTime = moment(start, format);
    const toTime = moment(end, format);

    return moment().isBetween(fromTime, toTime, "minutes", "[]");
  }

  return false;
};
