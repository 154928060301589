import * as React from "react";
import Box from "@mui/material/Box";

import { ReactComponent as PrevIcon } from "../assets/images/prev_icon.svg";

const circleSize = 65;

const AppPreviousBtn = (props) => {
  const { isCircle } = props;
  return (
    <Box
      sx={{ ...styles.prevNextImgMainView, borderRadius: isCircle ? "50%" : 0 }}
      onClick={() => props.onPrevBtnClick()}
    >
      {props.image ? (
        <img alt="" src={props.image} style={styles.prevNextImg} />
      ) : (
        <PrevIcon width={circleSize} height={circleSize} />
      )}
    </Box>
  );
};

export default AppPreviousBtn;

const styles = {
  prevNextImgMainView: {
    width: circleSize,
    height: circleSize,
    zIndex: 5,
    overflow: "hidden",
    cursor: "pointer",
  },
  prevNextImg: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
  },
};
