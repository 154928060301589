import React from "react";

import { FOODO_URL } from "../constants";

const Home = () => {
  return (
    <iframe
      src={FOODO_URL}
      style={{
        display: "block",
        width: "100%",
        height: "100%",
        border: "none",
      }}
    />
  );
};

export default Home;
