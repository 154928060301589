import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import './../assets/css/custom.css';

import {ReactComponent as RightArrowIcon} from "../assets/images/right_arrow.svg";

const ARWebAddress = (props) => {
  let {address, checkoutFontColor = "#212529"} = props;

  const addressTextStyle = {
    ...styles.addressText,
    color : `${checkoutFontColor} !important`,
  }

  return (
    <Box mt={2} sx={styles.addressView}>
      <Box sx={styles.addressSubView}>
        <Typography sx={{
          ...styles.addressNameText,
          color : `${checkoutFontColor} !important`,
        }}>
          {address.name}
        </Typography>
        <Box my={1}>
          <Typography sx={addressTextStyle}>{address.email}</Typography>
          <Typography sx={addressTextStyle}>+{address.phoneCode} {address.phone}</Typography>
        </Box>
        {address.addressTitle ? <Typography sx={addressTextStyle}>{address.addressTitle}</Typography> : null}
        <Typography sx={addressTextStyle}>{address.address}</Typography>
      </Box>
      {props.page == 'checkout' ?
        <RightArrowIcon width={25} height={25} style={{transform: props.locale == 'ar' ? "scaleX(-1)" : 'unset'}}/>
        : null}
    </Box>
  )
}

export default ARWebAddress;

const styles = {
  addressView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: '4px',
    p: 2
  },
  addressSubView: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  addressNameText: {
    color: '#212529',
    fontSize: 14,
    fontFamily: 'GilroySemiBold',
  },
  addressText: {
    color: '#212529',
    fontSize: 14,
    fontFamily: 'GilroyMedium',
  },
};
