import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import styles from "./VrScreenDatePicker.module.css";

const VrScreenDatePicker = (props) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  let inputLabelStyle = {
    color: props?.labelColor
      ? `${props.labelColor} !important`
      : "#FFF !important",
    fontSize: 15,
    fontFamily: "BauhausLight",
  };

  return (
    <Box className={styles.mainView}>
      {props.icon ? <Box>{props.icon}</Box> : null}
      <Box ml={2} className={styles.textInput}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={props.value}
            format={props.inputFormat}
            label={props.label}
            onChange={props.onChange}
            open={datePickerOpen}
            onClose={() => setDatePickerOpen(false)}
            maxDate={props.maxDate}
            slotProps={{
              textField: {
                variant: "standard",
                sx: {
                  "& .MuiInput-root": {
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #FFF",
                    },
                    "& .MuiInput-input": {
                      color: props?.inputColor
                        ? `${props.inputColor} !important`
                        : "#FFF",
                      fontSize: 15,
                      fontFamily: "BauhausMedium",
                    },
                  },
                  "&.MuiTextField-root": {
                    width: "100%",
                  },
                },
                InputLabelProps: {
                  sx: {
                    ...inputLabelStyle,
                    "&.Mui-focused": {
                      color: props?.labelColor
                        ? `${props.labelColor} !important`
                        : "#FFF !important",
                    },
                  },
                },
                inputProps: {
                  color: props?.inputColor ? props.inputColor : "#FFF",
                  fontFamily: "BauhausMedium",
                },
                InputProps: {
                  endAdornment: null,
                },
                onClick: () => setDatePickerOpen(true),
              },
            }}
          />
        </LocalizationProvider>
        <Typography className={styles.error}>{props?.error}</Typography>
      </Box>
    </Box>
  );
};

export default VrScreenDatePicker;
