import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { ReactComponent as SettingsIcon } from "../assets/images/settings.svg";

import styles from "./MenuListHeader.module.css";

export let MENU_HEADER_HEIGHT = 0;

const MenuListHeader = (props) => {
  return (
    <Grid
      container
      alignItems={"center"}
      item
      xs={12}
      py={1}
      className={styles.topView}
      px={`${props.paddingHorizontal}px`}
      sx={{ height: MENU_HEADER_HEIGHT }}
    >
      <Box className={styles.topLeftView}>
        <Typography className={styles.text1}>HENNUR</Typography>
        <Typography className={styles.text2}>
          #T40 <span className={styles.otpText}>OTP:</span> 567687
        </Typography>
      </Box>
      <Box className={styles.topRightView}>
        <Typography className={styles.text3} mr={0.5}>
          Call Waiter
        </Typography>
        <SettingsIcon width={22} height={22} style={{ cursor: "pointer" }} />
      </Box>
    </Grid>
  );
};

export default MenuListHeader;
