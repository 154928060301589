export const UPDATE_ORDER_TYPE = "UPDATE_ORDER_TYPE";
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";
export const UPDATE_USER_ADDRESS_LIST = "UPDATE_USER_ADDRESS_LIST";
export const UPDATE_RESTAURANT_DETAIL = "UPDATE_RESTAURANT_DETAIL";
export const UPDATE_PAYMENT_DETAIL = "UPDATE_PAYMENT_DETAIL";
export const UPDATE_LOCALE = "UPDATE_LOCALE";
export const UPDATE_SELECTED_MENU_DATA = "UPDATE_SELECTED_MENU_DATA";
export const UPDATE_LAST_VIEWED_MENU = "UPDATE_LAST_VIEWED_MENU";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const CLEAR_CART_DATA = "CLEAR_CART_DATA";
export const UPDATE_CONFIRMED_ORDER = "UPDATE_CONFIRMED_ORDER";

export const UPDATE_REST_DATA = "UPDATE_REST_DATA";
export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";
export const UPDATE_CATEGORY_LIST = "UPDATE_CATEGORY_LIST";
export const UPDATE_MENU_LIST = "UPDATE_MENU_LIST";
export const UPDATE_MODEL_LIST = "UPDATE_MODEL_LIST";
export const UPDATE_COURSE_LIST = "UPDATE_COURSE_LIST";
