import * as actionTypes from "./types";

export const updateRestData = (data) => ({
  type: actionTypes.UPDATE_REST_DATA,
  payload: data,
});

export const updateTableData = (data) => ({
  type: actionTypes.UPDATE_TABLE_DATA,
  payload: data,
});

export const updateCategoryList = (data) => ({
  type: actionTypes.UPDATE_CATEGORY_LIST,
  payload: data,
});

export const updateMenuList = (data) => ({
  type: actionTypes.UPDATE_MENU_LIST,
  payload: data,
});

export const updateModelList = (data) => ({
  type: actionTypes.UPDATE_MODEL_LIST,
  payload: data,
});

export const updateCourseList = (data) => ({
  type: actionTypes.UPDATE_COURSE_LIST,
  payload: data,
});
