import React, { forwardRef, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import {
  updateCartItems,
  updateRestaurantDetail,
  updateSelectedMenuData,
} from "../redux/actions/restaurantDataActions";

import ARWebMenuModal from "../components/ARWebMenuModal";
import ARWebAllergensNutritions from "../components/ARWebAllergensNutritions";
import ARWebOrderNowSection from "../components/ARWebOrderNowSection";
import ARWebLoading from "../components/ARWebLoading";
import Loader from "../components/Loader";
import { checkRestTemplateData } from "./Restaurants";

import { CURRENCY_SYMBOL, MENU_TYPE, TABLE, THEME_COLOR } from "../constants";

import "../assets/css/custom.css";

import { ReactComponent as RightArrow } from "../assets/images/right_arrow.svg";
import { ReactComponent as RightLightArrow } from "../assets/images/right_light_arrow.svg";
import { ReactComponent as LeftDarkArrow } from "../assets/images/left_dark_arrow.svg";
import { ReactComponent as LeftArrow } from "../assets/images/left_arrow.svg";
import { ReactComponent as LeftWhiteArrow } from "../assets/images/left_white_arrow.svg";
import { ReactComponent as Back } from "../assets/images/back.svg";
import { ReactComponent as Clock } from "../assets/images/clock.svg";
import { ReactComponent as MenuImg } from "../assets/images/file1.svg";
import { ReactComponent as ARMenuImg } from "../assets/images/ar_menu.svg";
import { ReactComponent as CartIcon } from "../assets/images/cart.svg";

import i18next from "../constants/i18n";
import { getRequiredModifierPrice } from "../constants/commonFunc";

const COLOR1 = "#D87025";

const theme_color = {
  temp1: THEME_COLOR,
  temp2: COLOR1,
};

const ar_view = {
  temp1: "rgba(255, 227, 66, 0.8)",
  temp2: "rgba(216, 112, 37, 0.9)",
};

const cat_head_style = {
  temp1: {},
  temp2: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
};

const font_color = {
  temp1: "#000000",
  temp2: "#FFFFFF",
};

const ar_logo_style = {
  temp1: {
    g: { fill: "#000000" },
  },
  temp2: {
    g: { fill: "#FFFFFF" },
    height: { xs: 94 / 2.8, md: 120 / 2.8 },
  },
};

const fixed_title_font_styles = {
  temp1: {
    color: "#FFFFFF",
  },
  temp2: {
    color: COLOR1,
    fontSize: 20,
    fontFamily: "BauhausMedium",
    fontStyle: "italic",
  },
};

const price_view = {
  temp1: THEME_COLOR,
  temp2: "#0d5f13",
};

const cat_main_title_style = {
  temp1: {},
  temp2: {
    fontSize: 20,
  },
};

const cat_title_style = {
  temp1: {},
  temp2: {
    fontSize: 16,
    fontFamily: "GilroyMedium",
  },
};

const cat_sel_title_style = {
  temp1: {},
  temp2: {
    fontSize: 16,
    fontFamily: "GilroySemiBold",
  },
};

const kcal_style = {
  temp1: {},
  temp2: {
    color: "#000",
    fontFamily: "GilroyBold",
  },
};

const menu_type_style = {
  temp1: {},
  temp2: {
    color: "#000",
    fontFamily: "GilroyBold",
  },
};

const menu_title_style = {
  temp1: {},
  temp2: {
    fontFamily: "GilroyBold",
  },
};

const menu_img_style = {
  temp1: {},
  temp2: {
    width: { xs: 110, md: 125 },
    height: "120px",
  },
};

const menu_view_style = {
  temp1: {},
  temp2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

const symbol = CURRENCY_SYMBOL;

let cat_page_size = 30000;
let menu_page_size = 30000;

let TOP_VIEW_HEIGHT = 120;
let CAT_LIST_HEIGHT = 50;

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme, template }) => ({
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#AAA",
    fontFamily: "GilroySemiBold",
    ...cat_title_style[template],
    "&.Mui-selected": {
      color: "#000",
      fontFamily: "GilroyBlack",
      ...cat_sel_title_style[template],
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))(({ template, underlineColor }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "4px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: template == "temp2" ? "80%" : "100%",
    width: "100%",
    backgroundColor: underlineColor,
    borderRadius: 10,
  },
}));

const filterCat = (cat_list) => {
  return cat_list.filter(
    (x) =>
      !x?.name?.toLowerCase()?.includes("modifier") &&
      x?.menu.filter((y) => y?.enabled).length
  );
};

const MenuList1 = (props) => {
  let { cartItems, restaurantData, restaurantId, addToCartExist } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantTime, setRestaurantTime] = useState("");
  const [restaurantImg, setRestaurantImg] = useState("");
  const [restaurantLogo, setRestaurantLogo] = useState("");
  const [selectedCatId, setSelectedCatId] = useState("");
  const [categoryMenuList, setCategoryMenuList] = useState(
    filterCat(props.categoryMenuList)
  );
  const [menuModelData, setMenuModelData] = useState(null);
  const [menuModelDataVisible, setMenuModelDataVisible] = useState(false);
  const [allergensModal, setAllergensModal] = useState(null);
  const [orderType, setOrderType] = useState(props.orderType);
  const [tableNumber, setTableNumber] = useState(props?.tableNumber);
  const [restTemplate, setRestTemplate] = useState("temp2");

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const menuScrollRef = useRef([]);
  const headerViewRef = useRef();

  const EXTRA_VAL = restTemplate == "temp2" ? -40 : 0;

  useEffect(() => {
    let data = checkRestTemplateData(restaurantId);
    setRestTemplate(data.TEMPLATE);
  }, [restaurantId, restaurantData]);

  useEffect(() => {
    if (restaurantData) {
      setRestaurantName(restaurantData?.name);
      let day = moment().format("dddd").toLowerCase();
      let time_data = restaurantData?.openHours[day];
      if (time_data.isOpen) {
        if (time_data.timings.length) {
          setRestaurantTime(
            "Open Until " +
              moment(time_data.timings[0]["to"], "HH:mm").format("h:m A")
          );
        }
      }
      if (restaurantData?.images.length) {
        setRestaurantImg(restaurantData?.images[0]);
      }
      setRestaurantLogo(restaurantData?.logo);
    }
  }, [restaurantData]);

  useEffect(() => {
    window.addEventListener("resize", function (e) {
      setScreenHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    if (categoryMenuList.length) {
      menuScrollRef.current = categoryMenuList.map(
        (_, i) => menuScrollRef.current[i] ?? React.createRef()
      );

      setSelectedCatId(categoryMenuList[0]?._id);
    }
  }, [categoryMenuList]);

  const handleMainScroll = (e) => {
    if (e.target.scrollTop > TOP_VIEW_HEIGHT) {
      document.getElementById(
        "categoryListView"
      ).style.top = `${headerViewRef.current?.offsetHeight}px`;
      document.getElementById("categoryListView").style.position = "sticky";
      document.getElementById("fixedHeaderView").style.position = "fixed";
      document.getElementById("fixedHeaderView").style.width = "100%";
      document.getElementById("fixedHeaderView").style.opacity = "1";
      let filter_cat = menuScrollRef.current.filter(
        (x) =>
          x.current?.offsetTop +
            x.current?.clientHeight +
            headerViewRef.current?.offsetHeight +
            CAT_LIST_HEIGHT +
            EXTRA_VAL +
            20 >=
          e.target.scrollTop
      );
      if (filter_cat.length > 0) {
        let cat_id = filter_cat[0].current?.attributes["cat-id"]["value"];
        if (selectedCatId != cat_id) {
          let findIndex = categoryMenuList.findIndex((x) => x._id == cat_id);
          if (findIndex >= 0) {
            setSelectedCatId(cat_id);
          }
        }
      }
    } else {
      if (e.target.scrollTop <= TOP_VIEW_HEIGHT + CAT_LIST_HEIGHT + EXTRA_VAL) {
        document.getElementById("fixedHeaderView").style.width = "90%";
        document.getElementById("fixedHeaderView").style.position = "absolute";
        document.getElementById("fixedHeaderView").style.opacity = "0";
        document.getElementById("categoryListView").style.top = "0px";
        document.getElementById("categoryListView").style.position = "relative";
      }
    }
  };

  const onCategoryClick = (cat) => {
    setSelectedCatId(cat._id);
    let cat_list = [...categoryMenuList];
    let find_index = cat_list.findIndex((x) => x._id == cat._id);
    if (find_index >= 0) {
      if (menuScrollRef.current[find_index]?.current) {
        let el = document.getElementById("mainViewId");
        el.scrollTo(
          0,
          menuScrollRef.current[find_index]?.current?.offsetTop +
            headerViewRef.current?.offsetHeight +
            CAT_LIST_HEIGHT +
            EXTRA_VAL +
            30
        );
      } else {
        menuScrollRef.current = categoryMenuList.map(
          (_, i) => menuScrollRef.current[i] ?? React.createRef()
        );
      }
    }
  };

  const onMenuImgClick = (data) => {
    props.onMenuArViewClick(data);
  };

  const ARMenuLogo = () => {
    let bg_color = ar_view[restTemplate];
    if (restaurantData?.themeColors?.arViewBgColor) {
      bg_color = restaurantData?.themeColors?.arViewBgColor;
      bg_color =
        bg_color.length == 4
          ? `${bg_color}${bg_color.substring(1)}e6`
          : `${bg_color}e6`;
    }
    return (
      <Box
        sx={{
          ...styles.arMenuDiv,
          ...ar_logo_style[restTemplate],
          backgroundColor: bg_color,
        }}
      >
        <ARMenuImg width={22} height={22} />
      </Box>
    );
  };

  const ARMenuCartCount = ({ type }) => {
    let cartCount = 0;

    cartItems.map((item) => {
      cartCount = cartCount + item.count;
    });

    return (
      <Box
        onClick={props.onCartClick}
        sx={{
          ...styles.cartMainView,
          right: type == "full_header" ? 15 : { xs: 48, md: 60 },
        }}
      >
        <Box>
          <CartIcon width={25} height={25} />
          <Box sx={styles.cartCountView}>
            <Typography sx={styles.cartCountText}>
              {cartCount > 9 ? "9+" : cartCount}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const onAddMenuItemToCart = (item, note, options, modifiers, count) => {
    let list = [
      ...cartItems.filter(
        (x) => x._idRestaurant == props.restaurantDetail?._id
      ),
    ];
    if (restaurantId != props.restaurantDetail?._id) {
      list = [];
    }
    if (count == 0) {
      list = [...list].filter((x) => x._id != item._id);
    } else {
      let findIndex = list.findIndex((x) => x._id == item._id);
      if (findIndex >= 0) {
        list[findIndex]["count"] = count;
        list[findIndex]["note"] = note;
        list[findIndex]["selectedOptions"] = options;
        list[findIndex]["selectedModifiers"] = modifiers;
      } else {
        list = [
          ...list,
          {
            ...item,
            count: count,
            note: note,
            selectedOptions: options,
            selectedModifiers: modifiers,
          },
        ];
      }
    }
    if (restaurantId != props.restaurantDetail?._id) {
      props.updateRestaurantDetail(restaurantData);
    }
    props.updateCartItems(list);
    let catIndex = categoryMenuList.findIndex((x) => x._id == item._idCategory);
    if (catIndex >= 0) {
      let cat_list = [...categoryMenuList];
      let menuIndex = cat_list[catIndex]["menu"].findIndex(
        (x) => x._id == item._id
      );
      if (menuIndex >= 0) {
        cat_list[catIndex]["menu"][menuIndex]["count"] = count;
        cat_list[catIndex]["menu"][menuIndex]["note"] = note;
        cat_list[catIndex]["menu"][menuIndex]["selectedOptions"] = options;
        cat_list[catIndex]["menu"][menuIndex]["selectedModifiers"] = modifiers;
        setCategoryMenuList(cat_list);
      }
    }
    if (count > 0) {
      setMenuModelDataVisible(false);
      // setMenuModelData(null)
    }
  };

  const onSetMenuModelData = (item) => {
    let findIndex = cartItems.findIndex(
      (x) => x._idCategory == item._idCategory && x._id == item._id
    );
    let count = 0;
    let note = "";
    let options = [];
    if (findIndex >= 0) {
      count = cartItems[findIndex]["count"];
      note = cartItems[findIndex]["note"];
      options = props.cartItems[findIndex]["selectedOptions"];
    }
    setMenuModelData({
      ...item,
      count: count,
      note: note,
      selectedOptions: options,
    });
    setMenuModelDataVisible(true);
  };

  const onAddSubMenuItemToCart = (type, item) => {
    let list = [...cartItems];
    let findIndex = list.findIndex((x) => x._id == item._id);
    if (findIndex >= 0) {
      let count = list[findIndex]?.count ? list[findIndex]?.count : 0;
      let options = list[findIndex]?.selectedOptions
        ? list[findIndex]?.selectedOptions
        : [];
      if (type == "-" && count > 0) {
        count = count - 1;
        options = options.filter((x, i) => i < count);
      }
      if (type == "+") {
        count = count + 1;

        let opts = [];
        list[findIndex]?.options?.map((opt) => {
          if (opt.required && opt.items.length) {
            opts.push({ _idOption: opt._id, item: opt.items[0] });
          }
        });
        options.push(opts);
      }
      list[findIndex]["count"] = count;
      list[findIndex]["selectedOptions"] = options;

      if (count == 0) {
        list = [...list].filter((x) => x._id != item._id);
      }

      let catIndex = categoryMenuList.findIndex(
        (x) => x._id == item._idCategory
      );
      if (catIndex >= 0) {
        let cat_list = [...categoryMenuList];
        let menuIndex = cat_list[catIndex]["menu"].findIndex(
          (x) => x._id == item._id
        );
        if (menuIndex >= 0) {
          cat_list[catIndex]["menu"][menuIndex]["count"] = count;
          cat_list[catIndex]["menu"][menuIndex]["selectedOptions"] = options;
          setCategoryMenuList(cat_list);
        }
      }
    }
    props.updateCartItems(list);
  };

  const onBackBtnClick = () => {
    props.onBackBtnClick();
  };

  const TabScrollBtn = forwardRef((props, ref) => {
    const { direction, disabled, ...other } = props;

    return (
      <ButtonBase component="div" ref={ref} {...other}>
        {direction === "right" ? (
          <Grid sx={{ cursor: "pointer" }}>
            {!disabled ? <RightArrow /> : <RightLightArrow />}
          </Grid>
        ) : (
          <Grid sx={{ cursor: "pointer" }}>
            {!disabled ? <LeftDarkArrow /> : <LeftArrow />}
          </Grid>
        )}
      </ButtonBase>
    );
  });

  const HeaderView = (
    <Box
      px={2}
      pt={5}
      pb={2}
      style={{
        ...styles.topView,
        backgroundImage: `url(${
          restaurantData?.themeColors?.headerBg
            ? restaurantData?.themeColors?.headerBg
            : restaurantImg
        })`,
      }}
      dir="ltr"
    >
      <Box className="app-restaurant-bg-shade" style={styles.bgShadeImage} />
      <Box style={styles.backBtn} onClick={onBackBtnClick}>
        <Back width={30} height={30} />
      </Box>
      <ARMenuCartCount type={"full_header"} />
      <Box sx={styles.restTitleView}>
        <Box sx={styles.restImgMainView}>
          <Box sx={styles.restImgView}>
            {restaurantLogo ? (
              <img alt="" src={restaurantLogo} style={styles.restImg} />
            ) : null}
          </Box>
        </Box>
        <Box sx={styles.titleView}>
          <Typography
            sx={{
              ...styles.titleText,
              paddingBottom: restaurantTime ? 0 : 2,
            }}
          >
            {restaurantName}
          </Typography>
          {restaurantTime ? (
            <Box sx={styles.timeView}>
              <Clock width={20} height={20} />
              <Typography sx={styles.timeText}>{restaurantTime}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );

  const HeaderView2 = (
    <Box
      px={2}
      py={2}
      style={{
        ...styles.topView,
        backgroundImage: `url(${
          restaurantData?.themeColors?.headerBg
            ? restaurantData?.themeColors?.headerBg
            : restaurantImg
        })`,
        alignItems: "center",
        marginBottom: "-20px",
      }}
      dir="ltr"
    >
      <Box
        className="app-restaurant-bg-shade"
        style={styles.bgShadeImage}
        sx={{ backgroundColor: "#00000092", backdropFilter: "blur(10px)" }}
      />
      <Box
        style={styles.backBtn}
        onClick={onBackBtnClick}
        sx={{ "#backArrow": { fill: "#FFFFFF" } }}
      >
        <Back width={30} height={30} />
      </Box>
      <Box sx={styles.restTitleView}>
        {restaurantLogo ? (
          <img alt="" src={restaurantLogo} style={styles.resLogo} />
        ) : null}
      </Box>
    </Box>
  );

  const FixedHeaderView = (
    <Box
      ref={headerViewRef}
      px={2}
      pt={1}
      pb={1}
      id="fixedHeaderView"
      sx={{
        ...styles.headerView,
        backgroundImage: `url(${
          restaurantData?.themeColors?.headerBg
            ? restaurantData?.themeColors?.headerBg
            : restaurantImg
        })`,
      }}
      dir="ltr"
    >
      <Box
        className="app-restaurant-bg-shade"
        style={styles.bgShadeImage}
        sx={
          restTemplate == "temp2"
            ? { backdropFilter: "blur(10px)", zIndex: 1 }
            : null
        }
      />
      <Box
        style={{
          ...styles.backBtn2,
          marginLeft: props.locale == "ar" ? 15 : 0,
          marginRight: props.locale == "ar" ? 0 : 15,
        }}
        onClick={onBackBtnClick}
      >
        <LeftWhiteArrow width={30} height={30} />
      </Box>
      {restTemplate == "temp1" ? (
        <ARMenuCartCount
          type={props.locale == "ar" ? "full_header" : "fixed_header"}
        />
      ) : null}
      {restTemplate == "temp1" ? (
        <Typography
          sx={{
            ...styles.titleText,
            ...fixed_title_font_styles[restTemplate],
          }}
        >
          {restaurantName}
        </Typography>
      ) : restaurantLogo ? (
        <img
          alt=""
          src={restaurantLogo}
          style={{ height: "25px", width: "164px", zIndex: 10 }}
        />
      ) : null}
    </Box>
  );

  if (isLoading) {
    return (
      // <ARWebLoading/>
      <Loader height={{ height: window.innerHeight }} />
    );
  }

  return (
    <Grid
      onScroll={handleMainScroll}
      id="mainViewId"
      item
      xs={12}
      sx={styles.mainView}
    >
      {restTemplate == "temp2" ? HeaderView2 : HeaderView}
      {FixedHeaderView}
      {categoryMenuList.length ? (
        <Grid
          id="categoryListView"
          dir="ltr"
          sx={{ ...styles.catListView, ...cat_head_style[restTemplate] }}
        >
          {/*<Box sx={styles.searchView}>*/}
          {/*  <Search width={25} height={25}/>*/}
          {/*</Box>*/}
          <Grid
            id="catListScrollId"
            className="ar-rest-cat-list-scrollbar"
            sx={{
              width: { xs: window.innerWidth, sm: window.innerWidth },
            }}
            style={{
              overflowX: "scroll",
              marginTop: 10,
            }}
          >
            <StyledTabs
              id="catListId"
              className="ar-rest-cat-list-scrollbar"
              sx={{
                width: { xs: window.innerWidth, md: window.innerWidth - 20 },
              }}
              underlineColor={
                restaurantData?.themeColors?.categoryUnderlineColor
                  ? restaurantData?.themeColors?.categoryUnderlineColor
                  : theme_color[restTemplate]
              }
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile={false}
              ScrollButtonComponent={(e) => <TabScrollBtn {...e} />}
              orientation="horizontal"
              value={selectedCatId}
              template={restTemplate}
            >
              {categoryMenuList.map((cat, i) => {
                return (
                  <StyledTab
                    key={i}
                    id={cat._id + "CatId"}
                    value={cat._id}
                    label={cat.name}
                    onClick={() => onCategoryClick(cat)}
                    template={restTemplate}
                  />
                );
              })}
            </StyledTabs>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12} style={{ ...styles.contentView }}>
        {categoryMenuList.map((cat, i2) => {
          return (
            <Grid
              key={i2}
              ref={menuScrollRef.current[i2]}
              cat-id={cat._id}
              pb={3}
            >
              <Grid sx={styles.categoryNameView} dir="ltr">
                <Typography
                  sx={{
                    ...styles.categoryName,
                    ...cat_main_title_style[restTemplate],
                  }}
                >
                  {cat.name}
                </Typography>
              </Grid>
              {cat.menu.length ? (
                cat.menu.map((menu, i) => {
                  const currency = symbol?.[menu?.price?.currency];
                  const itemPrice = (
                    menu?.price?.value
                      ? menu.price.value
                      : getRequiredModifierPrice(
                          menu?.modifiers,
                          props.menuList
                        )
                  )?.toFixed(2);
                  const hideMenuThumbNail =
                    menu?.extras?.hideMenuThumbNailImages;
                  return (
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      key={i}
                      sx={styles.menuDataView}
                      onClick={() => onSetMenuModelData(menu)}
                    >
                      <Grid
                        pl={2}
                        pr={hideMenuThumbNail ? 0 : 2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {!hideMenuThumbNail ? (
                          menu.images.length ? (
                            <Box
                              sx={{
                                ...styles.menuImage,
                                ...menu_img_style[restTemplate],
                                cursor:
                                  menu.modelId != null && menu.modelId != ""
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() => onMenuImgClick(menu)}
                            >
                              <img
                                src={menu.images[0]}
                                width={"100%"}
                                height={"100%"}
                                style={styles.menuImageStyle}
                              />
                              {menu.modelId != null && menu.modelId != "" ? (
                                <ARMenuLogo />
                              ) : null}
                            </Box>
                          ) : (
                            <Box
                              onClick={() => onMenuImgClick(menu)}
                              sx={{
                                ...styles.menuImage,
                                ...menu_img_style[restTemplate],
                                cursor:
                                  menu.modelId != null && menu.modelId != ""
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <MenuImg width={"40%"} height={"40%"} />
                              {menu.modelId != null && menu.modelId != "" ? (
                                <ARMenuLogo />
                              ) : null}
                            </Box>
                          )
                        ) : null}
                      </Grid>
                      <Grid
                        mr={1}
                        style={{
                          width: "100%",
                          alignItems: "flex-start",
                          ...menu_view_style[restTemplate],
                        }}
                      >
                        <Typography
                          className="first-char-capital"
                          sx={{
                            ...styles.menuName,
                            ...menu_title_style[restTemplate],
                          }}
                        >
                          {menu.name}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              ...styles.menuPriceView,
                              backgroundColor: restaurantData?.themeColors
                                ?.itemPriceBgColor
                                ? restaurantData?.themeColors?.itemPriceBgColor
                                : price_view[restTemplate],
                            }}
                          >
                            <Typography
                              sx={{
                                ...styles.menuPrice,
                                color: restaurantData?.themeColors
                                  ?.itemPriceFontColor
                                  ? restaurantData?.themeColors
                                      ?.itemPriceFontColor
                                  : font_color[restTemplate],
                              }}
                            >
                              {currency}
                              {itemPrice}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={[
                            styles.kcalMainView,
                            props.locale == "ar"
                              ? { justifyContent: "flex-end" }
                              : { justifyContent: "unset" },
                          ]}
                          dir="ltr"
                        >
                          {menu.calories ? (
                            <Box sx={styles.kcalTextView}>
                              <Typography
                                sx={{
                                  ...styles.kcalText,
                                  ...kcal_style[restTemplate],
                                }}
                              >
                                {menu.calories ? menu.calories : 0} kcal
                              </Typography>
                            </Box>
                          ) : null}
                          {menu?.extras?.menuType ? (
                            <>
                              {menu.calories ? (
                                <Typography sx={styles.lineText}>
                                  {" "}
                                  |{" "}
                                </Typography>
                              ) : null}
                              <Box sx={styles.foodTypeView}>
                                {
                                  MENU_TYPE.filter(
                                    (z) => z.title == menu?.extras?.menuType
                                  )?.[0]?.["icon"]
                                }
                                <Typography
                                  sx={{
                                    ...styles.foodTypeText,
                                    ...menu_type_style[restTemplate],
                                  }}
                                >
                                  {menu?.extras?.menuType}
                                </Typography>
                              </Box>
                            </>
                          ) : null}
                          {/*{menu?.isVeg ?*/}
                          {/*  <Box sx={styles.foodTypeView}>*/}
                          {/*    <VegIcon width={20} height={20}/>*/}
                          {/*    <Typography sx={styles.foodTypeText}> Vegetarian</Typography>*/}
                          {/*  </Box> :*/}
                          {/*  <Box sx={styles.foodTypeView}>*/}
                          {/*    <NovegIcon width={20} height={20}/>*/}
                          {/*    <Typography sx={styles.foodTypeText}> Non Vegetarian</Typography>*/}
                          {/*  </Box>}*/}
                          {/*<Box sx={styles.foodTypeView}>*/}
                          {/*  <PlantBasedIcon width={20} height={20}/>*/}
                          {/*  <Typography sx={styles.foodTypeText}> Plant based</Typography>*/}
                          {/*</Box>*/}
                          {/*<Box sx={styles.foodTypeView}>*/}
                          {/*  <SeafoodIcon width={20} height={20}/>*/}
                          {/*  <Typography sx={styles.foodTypeText}> Sea food</Typography>*/}
                          {/*</Box>*/}
                        </Box>
                        <Typography
                          className={
                            restTemplate == "temp2"
                              ? "app-rest-menu-desc"
                              : "app-restaurant-menu-desc"
                          }
                          sx={styles.menuDesc}
                        >
                          {menu.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Grid sx={styles.noPrdtView}>
                  <Typography sx={styles.noPrdtText}>No Items</Typography>
                </Grid>
              )}
            </Grid>
          );
        })}
        {categoryMenuList.length ? (
          <div
            style={{
              height:
                screenHeight -
                (headerViewRef.current?.offsetHeight
                  ? headerViewRef.current?.offsetHeight +
                    CAT_LIST_HEIGHT +
                    EXTRA_VAL
                  : TOP_VIEW_HEIGHT) -
                (menuScrollRef.current[menuScrollRef.current.length - 1]
                  ?.current?.offsetHeight <
                window.innerHeight - TOP_VIEW_HEIGHT
                  ? menuScrollRef.current[menuScrollRef.current.length - 1]
                      ?.current?.offsetHeight
                  : window.innerHeight - TOP_VIEW_HEIGHT),
            }}
          />
        ) : null}
      </Grid>
      <ARWebMenuModal
        page={"menu"}
        locale={props.locale}
        visible={menuModelDataVisible}
        data={menuModelData}
        menuList={props.menuList}
        onClose={() => setMenuModelDataVisible(false)}
        onAddMenuItemToCart={onAddMenuItemToCart}
        arModelClick={onMenuImgClick}
        onAllergensClick={() => {
          setAllergensModal(menuModelData);
          // setMenuModelData(null);
        }}
        template={restTemplate}
        addToCartExist={addToCartExist}
      />
      <ARWebAllergensNutritions
        data={allergensModal}
        onClose={() => setAllergensModal(null)}
      />
      {cartItems.filter((x) => x._idRestaurant == props.restaurantDetail?._id)
        .length > 0 &&
      addToCartExist &&
      ((orderType == TABLE && tableNumber) || orderType != TABLE) ? (
        <ARWebOrderNowSection
          locale={props.locale}
          btnText={
            orderType == TABLE
              ? i18next.t("Place Order")
              : i18next.t("Order Now")
          }
          zIndex={allergensModal || menuModelDataVisible ? 5 : 1500}
          cartItems={cartItems}
          onOrderNow={props.onCartClick}
          onAddSubMenuItemToCart={onAddSubMenuItemToCart}
        />
      ) : null}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.restaurantData.cartItems,
    restaurantDetail: state.restaurantData.restaurantDetail,
    locale: state.restaurantData.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartItems: (data) => dispatch(updateCartItems(data)),
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    updateSelectedMenuData: (data) => dispatch(updateSelectedMenuData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuList1);

const styles = {
  mainView: {
    backgroundColor: "#FFF",
    height: window.innerHeight,
    position: "relative",
    overflow: "auto",
  },
  topView: {
    position: "relative",
    backgroundSize: "cover",
    height: TOP_VIEW_HEIGHT,
    display: "flex",
    alignItems: "flex-end",
  },
  bgShadeImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  backBtn: {
    position: "absolute",
    top: 10,
    left: 15,
  },
  cartMainView: {
    position: "absolute",
    cursor: "pointer",
    top: 14,
    zIndex: 50,
  },
  cartCountView: {
    backgroundColor: "#FF2727",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "10px",
    top: -5,
    right: -5,
  },
  cartCountText: {
    color: "#FFF",
    fontSize: 9,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  restTitleView: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  restImgMainView: {
    width: 80,
  },
  restImgView: {
    width: 78,
    height: 78,
    borderRadius: 39,
    overflow: "hidden",
  },
  restImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  resLogo: {
    height: "100%",
    width: 190,
    objectFit: "contain",
  },
  titleView: {
    marginLeft: 1.5,
    minWidth: "60%",
  },
  titleText: {
    color: "#FFF",
    fontSize: 18,
    fontFamily: "GilroyBold",
    zIndex: 2,
  },
  headerView: {
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    width: "90%",
    top: 0,
    opacity: 0,
    zIndex: 10,
  },
  backBtn2: {
    zIndex: 2,
  },
  timeView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: 0.8,
  },
  timeText: {
    color: "#CCC",
    fontSize: 16,
    fontFamily: "GilroyMedium",
    ml: 0.5,
  },
  catListView: {
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    zIndex: 6,
    position: "relative",
    top: 0,
  },
  searchView: {
    mx: 2,
    mt: 2,
    cursor: "pointer",
  },
  contentView: {
    backgroundColor: "#FFF",
    position: "relative",
    zIndex: 1,
  },
  categoryNameView: {
    px: 2,
    mt: 2,
    mb: 1,
  },
  categoryName: {
    fontSize: 24,
    fontFamily: "GilroyBold",
    textTransform: "capitalize",
  },
  noPrdtView: {
    py: 2,
  },
  noPrdtText: {
    fontSize: 15,
    fontFamily: "GilroyLight",
    textAlign: "center",
  },
  menuDataView: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    borderBottom: `1px solid #EFE`,
    cursor: "pointer",
    pt: 2,
    pb: 2,
  },
  arMenuDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: { xs: 84 / 2.8, md: 110 / 2.8 },
    bottom: 0,
  },
  menuImage: {
    position: "relative",
    backgroundColor: "#EEE",
    width: { xs: 90, md: 125 },
    height: { xs: 84, md: 110 },
    borderRadius: 3,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuImageStyle: {
    objectFit: "cover",
  },
  menuName: {
    fontSize: 16,
    fontFamily: "GilroySemiBold",
    wordBreak: "break-word",
    lineHeight: "1.2",
  },
  menuPriceView: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 56,
    height: 28,
    borderRadius: 20,
    px: "10px",
    my: 1,
  },
  menuPrice: {
    fontSize: 16,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  kcalMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mb: 1,
  },
  kcalTextView: {},
  kcalText: {
    color: "#555",
    fontSize: 13,
    fontFamily: "GilroyMedium",
  },
  lineText: {
    color: "#EEE",
    fontSize: 13,
    fontFamily: "GilroyMedium",
    mx: 1,
  },
  foodTypeView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  foodTypeText: {
    color: "#555",
    fontSize: 13,
    fontFamily: "GilroyMedium",
    ml: 0.7,
  },
  menuDesc: {
    color: "#777",
    fontSize: 13,
    fontFamily: "GilroyMedium",
    lineHeight: "1.4",
    wordBreak: "break-word",
  },
};
