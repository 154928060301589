import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "./../assets/css/custom.css";

import { THEME_COLOR } from "../constants";

import { ReactComponent as CloseIcon } from "../assets/images/close3.svg";

import { checkAllergies, checkNutritions } from "../constants/commonFunc";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "4px",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "100%",
    width: "100%",
    backgroundColor: THEME_COLOR,
    borderRadius: 10,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#000",
    fontFamily: "GilroyBold",
    "&.Mui-selected": {
      color: "#000",
    },
  })
);

const ARWebAllergensNutritions = (props) => {
  const [tabList, setTabList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [allergyExist, setAllergyExist] = useState(false);
  const [nutritionExist, setNutritionExist] = useState(false);

  useEffect(() => {
    const allergensExist = checkAllergies(props.data?.allergies);
    const nutritionsExist = checkNutritions(props.data?.nutritions);

    const newTabList = [];
    if (allergensExist) newTabList.push("Allergens");
    if (nutritionsExist) newTabList.push("Nutritions");

    setSelectedTab(newTabList.length ? newTabList[0] : "");
    setTabList(newTabList);
    setAllergyExist(allergensExist);
    setNutritionExist(nutritionsExist);
  }, [props.data]);

  return (
    <Modal
      style={styles.modelView}
      open={props.data == null ? false : true}
      onClose={props.onClose}
      // hideBackdrop={true}
    >
      <Box sx={styles.mainView}>
        <Box sx={styles.headerView}>
          <Typography sx={styles.headerTitle}>
            {allergyExist ? "Allergens" : ""}
            {allergyExist && nutritionExist ? " & " : ""}
            {nutritionExist ? "Nutritions" : ""}
          </Typography>
          <Box sx={styles.closeBtn} onClick={props.onClose}>
            <CloseIcon width={30} height={30} />
          </Box>
        </Box>
        <StyledTabs
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile={false}
          orientation="horizontal"
          value={selectedTab}
        >
          {tabList.map((x, i) => {
            return (
              <StyledTab
                sx={{ width: "40%" }}
                key={i}
                value={x}
                label={x}
                onClick={() => setSelectedTab(x)}
              />
            );
          })}
        </StyledTabs>
        <Box sx={styles.dataMainView}>
          {selectedTab == "Allergens" ? (
            <Box>
              <Box sx={styles.titleView}>
                {allergyExist ? (
                  <Typography sx={styles.descText}>
                    {props.data?.allergies}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={styles.titleView}>
                {nutritionExist ? (
                  <Typography sx={styles.descText}>
                    {props.data?.nutritions}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ARWebAllergensNutritions;

const styles = {
  modelView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainView: {
    backgroundColor: "#FFF",
    width: "86%",
    borderRadius: 3,
    "&:focus": {
      outline: "none",
    },
    overflow: "hidden",
  },
  headerView: {
    position: "relative",
    backgroundColor: "#000",
    px: 3,
    py: 2,
  },
  headerTitle: {
    color: "#FFF",
    fontSize: 16,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  closeBtn: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    cursor: "pointer",
    right: 10,
    top: 0,
    bottom: 0,
  },
  dataMainView: {
    overflow: "auto",
    minHeight: 150,
    maxHeight: window.innerHeight - 200,
    px: 2,
    pt: 0,
    pb: 3,
  },
  titleView: {
    display: "flex",
    flexDirection: "row",
    mt: 2,
  },
  titleText: {
    color: "#000",
    fontSize: 15,
    fontFamily: "GilroyBold",
    mt: 2,
  },
  titleText1: {
    color: "#000",
    fontSize: 15,
    fontFamily: "GilroyBold",
  },
  subText: {
    color: "#000",
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
  descText: {
    color: "#555",
    fontSize: 13,
    fontFamily: "GilroyMedium",
  },
  descText1: {
    color: "#555",
    fontSize: 13,
    fontFamily: "GilroyMedium",
    ml: 0.5,
  },
};
