import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { ReactComponent as SupportedBrands } from "../../assets/images/supported-brands.svg";
import { ReactComponent as SecureLock } from "../../assets/images/secure-lock.svg";

const CheckoutFooter = () => {
	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				position: "relative",
				mt: 10,
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
                <SecureLock />
				
				<Typography variant='body1' fontSize={12} color='#777'>
					Guaranteed <strong>safe &amp; secure</strong> checkout.
				</Typography>
			</Box>

			<Divider
				sx={{
					width: "calc(100% - 90px)",
					backgroundColor: "rgba(0, 0, 0, 0.05)",
					margin: "10px",
				}}
			/>

			<Box
				sx={{
					position: "absolute",
					top: "-10px",
				}}
			>
				<SupportedBrands />
			</Box>
		</Box>
	);
};

export default CheckoutFooter;
