import React from "react";
import { Box } from "@mui/material";

import LoadingAnimation from "./LoadingAnimation";

const LoadingScreen = () => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			height='100vh'
		>
			<LoadingAnimation />
		</Box>
	);
};

export default LoadingScreen;
