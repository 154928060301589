import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { formatCurrency } from '../../../utils/utils';
import ExpressBlock from "./ExpressBlock";
import CardPaymentForm from "./CardPaymentForm";

const PaymentForm = ({
	amount,
	onSuccess,
	onCustomerInfoChange,
	paymentIntentId,
	clientSecret,
	setFinalLoading,
    isFinalLoading
}) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [isExpressCheckoutConfirming, setIsExpressCheckoutConfirming] =
		useState(false);

	const userDetails = useSelector(
		(state) => state.restaurantData.userAddressList[0]
	);
	const lineItems = useSelector((state) => state.restaurantData.cartItems);

	useEffect(() => {
		if (userDetails) {
			setName(userDetails.name);
			setEmail(userDetails.email);
		}
	}, [userDetails]);

	useEffect(() => {
		if (paymentIntentId && name && email) {
			onCustomerInfoChange(name, email);
		}
	}, [paymentIntentId, name, email, onCustomerInfoChange]);

	const onClickExpressCheckout = ({ resolve }) => {
		const options = {
			emailRequired: false,
			lineItems: lineItems.map((item) => ({
				name: item.name,
				amount: Math.round(item.price.value * 100),
			})),
			billingAddressRequired: false,
			shippingAddressRequired: false,
		};
		resolve(options);
	};

	return (
		<>
				<ExpressBlock
					paymentIntentId={paymentIntentId}
					onCustomerInfoChange={onCustomerInfoChange}
					name={name}
					email={email}
					clientSecret={clientSecret}
					onSuccess={onSuccess}
					setIsExpressCheckoutConfirming={setIsExpressCheckoutConfirming}
					onClickExpressCheckout={onClickExpressCheckout}
					amount={formatCurrency(amount)}
                    setFinalLoading={setFinalLoading}
                    isFinalLoading={isFinalLoading}
				/>

                <Divider sx={{ my: 2, opacity: 0 }} />

				<CardPaymentForm
					amount={amount}
					onSuccess={onSuccess}
					onCustomerInfoChange={onCustomerInfoChange}
					paymentIntentId={paymentIntentId}
					clientSecret={clientSecret}
					setFinalLoading={setFinalLoading}
				/>
		</>
	);
};

export default PaymentForm;
