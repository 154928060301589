import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Restaurants from "./containers/Restaurants";
import RestaurantMenu from "./containers/RestaurantMenu";
import RestaurantCart from "./containers/RestaurantCart";
import Home from "./containers/Home";
import Version from "./containers/Version";

import { connect } from "react-redux";
import { updateLocale } from "./redux/actions/restaurantDataActions";
import OrderSuccessPage from "./modules/eazy-peasy-checkout/components/pages/OrderSuccessPage";
import PaymentLandingPage from "./modules/eazy-peasy-checkout/components/pages/PaymentLandingPage";
import PastOrdersPage from "./modules/past-orders/PastOrdersPage";
import PastOrderDetailsPage from "./modules/past-orders/PastOrderDetailsPage";
import { TableProvider } from "./context/TableContext";

const App = (props) => {
	useEffect(() => {
		let locale = "en";
		let windowUrl = window.location.search;
		let params = new URLSearchParams(windowUrl);
		let locale_val = params.get("ln");
		if (locale_val) {
			locale = locale_val;
		}
		props.updateLocale(locale);
	}, []);

	useEffect(() => {
		if (props.locale == "ar") {
			document.documentElement.dir = "rtl";
		} else {
			document.documentElement.dir = "ltr";
		}
	}, [props.locale]);

	let restaurant_route = (
		<>
			<Route exact path='/cart' element={<RestaurantCart {...props} />} />
			<Route exact path='/cart/:id' element={<RestaurantCart {...props} />} />
			<Route
				exact
				path='/cart/:id/table/:table_id'
				element={<RestaurantCart {...props} />}
			/>
			<Route
				exact
				path='/r/cart/:slug'
				element={<RestaurantCart {...props} />}
			/>
			<Route
				exact
				path='/r/cart/:slug/table/:table_id'
				element={<RestaurantCart {...props} />}
			/>
			<Route
				exact
				path='/restaurant/:id'
				element={<Restaurants {...props} />}
			/>
			<Route
				exact
				path='/restaurant/:id/table'
				element={<Restaurants {...props} />}
			/>
			<Route
				exact
				path='/restaurant/:id/table/:table_id'
				element={<Restaurants {...props} />}
			/>

      <Route
        exact
        path="/restaurant/past-orders"
        element={<PastOrdersPage {...props} />}
      />

      <Route
        exact
        path="/restaurant/past-orders/order/:id"
        element={<PastOrderDetailsPage {...props} />}
      />

      <Route exact path="/r/:slug" element={<Restaurants {...props} />} />
      <Route exact path="/r/:slug/table" element={<Restaurants {...props} />} />
      <Route
        exact
        path="/r/:slug/table/:table_id"
        element={<Restaurants {...props} />}
      />
      <Route
        exact
        path="/restaurant_menu/:id"
        element={<RestaurantMenu {...props} />}
      />
      <Route
        exact
        path="/restaurant_menu/:id/table"
        element={<RestaurantMenu {...props} />}
      />
      <Route
        exact
        path="/restaurant_menu/:id/table/:table_id"
        element={<RestaurantMenu {...props} />}
      />
      <Route
        exact
        path="/r/menu/:slug"
        element={<RestaurantMenu {...props} />}
      />
      <Route
        exact
        path="/r/menu/:slug/table"
        element={<RestaurantMenu {...props} />}
      />
      <Route
        exact
        path="/r/menu/:slug/table/:table_id"
        element={<RestaurantMenu {...props} />}
      />

			<Route exact path='/r/:slug' element={<Restaurants {...props} />} />
			<Route exact path='/r/:slug/table' element={<Restaurants {...props} />} />
			<Route
				exact
				path='/r/:slug/table/:table_id'
				element={<Restaurants {...props} />}
			/>
			<Route
				exact
				path='/restaurant_menu/:id'
				element={<RestaurantMenu {...props} />}
			/>
			<Route
				exact
				path='/restaurant_menu/:id/table'
				element={<RestaurantMenu {...props} />}
			/>
			<Route
				exact
				path='/restaurant_menu/:id/table/:table_id'
				element={<RestaurantMenu {...props} />}
			/>
			<Route
				exact
				path='/r/menu/:slug'
				element={<RestaurantMenu {...props} />}
			/>
			<Route
				exact
				path='/r/menu/:slug/table'
				element={<RestaurantMenu {...props} />}
			/>
			<Route
				exact
				path='/r/menu/:slug/table/:table_id'
				element={<RestaurantMenu {...props} />}
			/>
		</>
	);

	const checkoutRoutes = (
		<>
			<Route
				path='/order-success/:paymentIntentId'
				element={<OrderSuccessPage />}
			/>
			<Route path='cart/payment' element={<PaymentLandingPage />} />
		</>
	);

	let routes = (
		<Routes>
			{restaurant_route}
			{checkoutRoutes}
			<Route exact path='/' element={<Home />} />
			<Route path='*' element={<Navigate replace to='/' />} />
			<Route path='/version' element={<Version />} />
		</Routes>
	);

	return (
		<BrowserRouter>
			<TableProvider>{routes}</TableProvider>
		</BrowserRouter>
	);
};

const mapStateToProps = (state) => {
	return {
		locale: state.restaurantData.locale,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateLocale: (data) => dispatch(updateLocale(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
