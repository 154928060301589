import { format } from "date-fns";

export const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
    }).format(amount);
};

export const formatDate = (dateString) => {
    if (!dateString) {
        return "Invalid date";
    }
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy, hh:mm a");
};
