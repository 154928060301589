import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AppNextBtn from "./AppNextBtn";
import AppPreviousBtn from "./AppPreviousBtn";

import { ReactComponent as MenuIcon } from "../assets/images/menu1.svg";

let WINDOW_HEIGHT = window.innerHeight;

let circle_size = window.innerWidth <= 400 ? window.innerWidth / 3 - 64 : 60;
let menu_size = circle_size + 8;

const AppNextPrevBtnTemp1 = (props) => {
  const {
    enterAframeAr,
    enterWebXrArMode,
    menuList,
    menuIndex,
    showPrevBtn,
    showNextBtn,
  } = props;

  let posStyle = { bottom: 0 };
  if (enterWebXrArMode) {
    posStyle = { top: WINDOW_HEIGHT + 40 };
  }
  return (
    <>
      {/*<Grid item xs={12} style={styles.bottomMainView}>*/}
      {/*  <Grid*/}
      {/*    container*/}
      {/*    item*/}
      {/*    xs={12}*/}
      {/*    px={2}*/}
      {/*    mb={2}*/}
      {/*    direction="row"*/}
      {/*    style={styles.bottomView}*/}
      {/*  >*/}
      <Box sx={{ ...styles.prevMainView, ...posStyle }}>
        {showPrevBtn ? (
          <AppPreviousBtn
            locale={props.locale}
            image={menuList?.[menuIndex - 1]?.["images"]?.[0]}
            onPrevBtnClick={props.onPrevBtnClick}
          />
        ) : null}
      </Box>
      {enterAframeAr || enterWebXrArMode ? null : (
        <Box sx={{ ...styles.menuBtnView, ...posStyle }}>
          {props.menuIcon ? (
            <Box sx={styles.iconView}>
              <img
                src={props.menuIcon}
                width={menu_size}
                height={menu_size}
                onClick={props.onRestMenuNavigate}
              />
            </Box>
          ) : (
            <Grid
              sx={styles.viewAllMainView}
              onClick={props.onRestMenuNavigate}
            >
              <Box sx={styles.viewAllContentMainView}>
                <MenuIcon width={24} height={24} />
                <Grid sx={styles.viewAllContentView}>
                  <Typography style={styles.viewAllText}>MENU</Typography>
                </Grid>
              </Box>
            </Grid>
          )}
        </Box>
      )}
      <Box sx={{ ...styles.nextMainView, ...posStyle }}>
        {showNextBtn ? (
          <AppNextBtn
            locale={props.locale}
            image={menuList?.[menuIndex + 1]?.["images"]?.[0]}
            onNextBtnClick={props.onNextBtnClick}
          />
        ) : null}
      </Box>
      {/*</Grid>*/}

      {/*<Grid container item xs={12} px={2} direction="row" style={styles.appDataView}>*/}
      {/*  <Grid item xs={2} style={styles.appLogoDiv}>*/}
      {/*    <LogoIcon width={52} height={52}/>*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={6.5} style={styles.appTitleMainDiv}>*/}
      {/*    <Typography style={styles.appTitleText}>FOODO App</Typography>*/}
      {/*    <Typography style={styles.appDescText}>Lorem ipsum dolor sit amet, consectetur adipiscin</Typography>*/}
      {/*  </Grid>*/}
      {/*  <Grid item xs={3.5} style={styles.downloadMainDiv}>*/}
      {/*    <Box style={styles.downloadDiv}>*/}
      {/*      <Typography style={styles.downloadText}>Download</Typography>*/}
      {/*    </Box>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      {/*</Grid>*/}
    </>
  );
};

export default AppNextPrevBtnTemp1;

const styles = {
  // bottomMainView: {
  //   position: "absolute",
  //   width: "100%",
  //   bottom: 0,
  //   zIndex: 7,
  // },
  // bottomView: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  // },
  prevMainView: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: 8,
    left: 0,
    px: 2,
    mb: 2.4,
  },
  nextMainView: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    zIndex: 7,
    right: 0,
    px: 2,
    mb: 2.4,
  },
  menuBtnView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 7,
    right: 0,
    left: 0,
    px: 2,
    mb: 2,
  },
  iconView: {
    width: menu_size,
    height: menu_size,
    borderRadius: menu_size / 2,
    overflow: "hidden",
    cursor: "pointer",
  },
  viewAllMainView: {
    width: menu_size,
    height: menu_size,
    borderRadius: menu_size / 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffe342",
    boxShadow: "1px 1px 18px 9px #ffe342",
    cursor: "pointer",
    zIndex: 5,
  },
  viewAllContentMainView: {
    width: menu_size - 8,
    height: menu_size - 8,
    borderRadius: (menu_size - 8) / 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
  },
  viewAllContentView: {
    marginTop: 0.6,
  },
  viewAllText: {
    color: "#ffe342",
    fontSize: menu_size / 8.5,
    fontFamily: "GilroyBold",
    cursor: "pointer",
    textAlign: "center",
    letterSpacing: 3.5,
    marginLeft: 3.5,
  },
  // appDataView: {
  //   backgroundColor: "#000",
  //   alignItems: "center",
  //   height: 90,
  //   marginTop: 10,
  // },
  // appLogoDiv: {
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   paddingRight: 4,
  // },
  // appTitleMainDiv: {
  //   paddingLeft: 10,
  // },
  // appTitleText: {
  //   color: "#fff",
  //   fontSize: 15,
  //   fontFamily: "GilroySemiBold",
  //   cursor: "pointer",
  //   marginBottom: 3,
  // },
  // appDescText: {
  //   color: "#AAA",
  //   fontSize: 12,
  //   fontFamily: "GilroySemiBold",
  //   cursor: "pointer",
  //   lineHeight: 1,
  // },
  // downloadMainDiv: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   paddingLeft: 4,
  // },
  // downloadDiv: {
  //   backgroundColor: "#FFF",
  //   width: 100,
  //   paddingBottom: 5,
  //   paddingTop: 5,
  //   borderRadius: 40,
  // },
  // downloadText: {
  //   color: "#000",
  //   fontSize: 15,
  //   fontFamily: "GilroyBold",
  //   cursor: "pointer",
  //   textAlign: "center",
  // },
};
