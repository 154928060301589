import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import restaurantDataReducer from "./restaurantDataReducer";
import apiDataReducer from "./apiDataReducer";

const persistConfig = {
  timeout: 0,
  key: "root",
  storage,
  blacklist: ["restaurantData", "apiData"],
};

const restaurantDataPersistConfig = {
  key: "restaurantData",
  storage,
  blacklist: ["lastViewedMenu", "orderType"],
  // blacklist: [
  //   'cartItems',
  //   'userAddressList',
  // ],
};

const apiDataPersistConfig = {
  key: "apiData",
  storage,
};

const rootReducer = combineReducers({
  restaurantData: persistReducer(
    restaurantDataPersistConfig,
    restaurantDataReducer
  ),
  apiData: persistReducer(apiDataPersistConfig, apiDataReducer),
});

export default persistReducer(persistConfig, rootReducer);
