import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import ARWebCartMenuItem from "./ARWebCartMenuItem";

import "./../assets/css/custom.css";

import { CURRENCY_SYMBOL } from "../constants";
import i18next from "../constants/i18n";

import { ReactComponent as UpArrowIcon } from "../assets/images/up_arrow_white.svg";
import { ReactComponent as DownArrowIcon } from "../assets/images/down_arrow_white.svg";
import { ReactComponent as DownArrowBlackIcon } from "../assets/images/down_arrow_black.svg";

import { getOrdersWithTotalPrice } from "../constants/commonFunc";

const BOTTOM_HEIGHT = 72;

const ARWebOrderNowSection = (props) => {
  const [orderReviewModalVisible, setOrderReviewModalVisible] = useState(false);

  let { cartItems } = props;

  const totalPrice = useMemo(() => {
    return getOrdersWithTotalPrice(cartItems, [])?.totalPrice?.toFixed(2) ?? 0;
  }, [cartItems]);

  const handleSetOrderReviewModalVisible = () => {
    setOrderReviewModalVisible(!orderReviewModalVisible);
  };

  return (
    <>
      <Box sx={styles.mainView} style={{ zIndex: props.zIndex }}>
        <Box
          sx={{ ...styles.totalMainView, py: 0.5, ml: 1 }}
          onClick={handleSetOrderReviewModalVisible}
        >
          {orderReviewModalVisible ? (
            <DownArrowIcon width={20} height={20} />
          ) : (
            <UpArrowIcon width={20} height={20} />
          )}
          <Box ml={2} sx={styles.totalSubView}>
            <Typography sx={styles.totalTitleText}>
              {i18next.t("Total")}
            </Typography>
            <Typography sx={styles.totalValText}>
              {props.cartItems.length
                ? CURRENCY_SYMBOL[props.cartItems[0]?.price?.currency]
                : null}
              {totalPrice}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.orderNowBtnView} onClick={props.onOrderNow}>
          <Typography sx={styles.orderNowBtnText}>{props.btnText}</Typography>
        </Box>
      </Box>
      <SwipeableDrawer
        sx={styles.modelView}
        anchor={"bottom"}
        open={orderReviewModalVisible}
        onClose={handleSetOrderReviewModalVisible}
        transitionDuration={{
          enter: 300,
          exit: 350,
        }}
      >
        <Box sx={styles.orderReviewModalView}>
          <Box sx={styles.orderReviewTitleView} mx={2.5} py={2} mt={1}>
            <Typography sx={styles.orderReviewTitle}>
              {i18next.t("Order Review")}
            </Typography>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={handleSetOrderReviewModalVisible}
            >
              <DownArrowBlackIcon width={20} height={20} />
            </Box>
          </Box>
          <Box sx={styles.dataMainView} px={2.5}>
            {props.cartItems.map((x, i) => {
              return (
                <ARWebCartMenuItem
                  key={i}
                  item={x}
                  locale={props.locale}
                  editable={true}
                  onAddSubMenu={props.onAddSubMenuItemToCart}
                />
              );
            })}
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ARWebOrderNowSection;

const styles = {
  mainView: {
    height: BOTTOM_HEIGHT,
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#000",
    padding: "2px 5px",
    bottom: 0,
    left: 0,
    right: 0,
  },
  totalMainView: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  },
  totalSubView: {},
  totalTitleText: {
    color: "#FFF",
    fontSize: 14,
    fontFamily: "GilroyRegular",
  },
  totalValText: {
    color: "#FFF",
    fontSize: 22,
    fontFamily: "GilroySemiBold",
  },
  orderNowBtnView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#FFF",
    width: 150,
    height: 50,
    borderRadius: 44,
  },
  orderNowBtnText: {
    color: "#000000",
    fontSize: 14,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  modelView: {
    "& .MuiPaper-root": {
      bottom: BOTTOM_HEIGHT,
    },
  },
  orderReviewModalView: {
    backgroundColor: "#FFF",
  },
  orderReviewTitleView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #707070",
  },
  orderReviewTitle: {
    color: "#000000",
    fontSize: 18,
    fontFamily: "GilroyBold",
  },
  dataMainView: {
    position: "relative",
    overflow: "auto",
    height: window.innerHeight * 0.75 - BOTTOM_HEIGHT,
  },
};
