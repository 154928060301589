import React, {useRef} from 'react';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {connect} from "react-redux";
import {updateOrderType} from "../redux/actions/restaurantDataActions";

import {ReactComponent as CloseIcon} from "../assets/images/close2.svg";

import './../assets/css/custom.css';

import {THEME_COLOR} from "../constants";

import i18next from './../constants/i18n'

const ARWebOrderTypeModal = (props) => {

  const onUpdate = (val) => {
    props.updateOrderType(val);
    props.onUpdate();
  }

  return (
    <Modal
      style={styles.modelView}
      open={props.visible}
      onClose={props.onClose}
    >
      <Box sx={styles.mainView}>
        <Box sx={styles.closeIconView}>
          <Box onClick={props.onClose} sx={styles.closeIcon}>
            <CloseIcon width={25} height={25}/>
          </Box>
        </Box>
        <Box sx={styles.subImgTitleView}>
          <Box mb={1} sx={styles.restImgView}>
            {props.restaurantLogo ? <img alt="" src={props.restaurantLogo} style={styles.restImg}/> : null}
          </Box>
          <Typography sx={styles.titleText}>{props.restaurantName}</Typography>
        </Box>

        <Box mt={4}>
          <Tabs sx={styles.btnView}
                value={props.orderType}>
            <Tab label={i18next.t('Delivery')} value="delivery" sx={styles.btnText}
                 onClick={() => onUpdate('delivery')}/>
            <Tab label={i18next.t('Pick Up')} value="pickup" sx={styles.btnText}
                 onClick={() => onUpdate('pickup')}/>
          </Tabs>
        </Box>
      </Box>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    orderType: state.restaurantData.orderType,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrderType: (data) => dispatch(updateOrderType(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ARWebOrderTypeModal);

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  mainView: {
    backgroundColor: '#FFF',
    width: {xs: '86%', md: '40%'},
    "&:focus": {
      outline: 'none',
    },
    overflow: 'hidden',
    minHeight: 200,
    borderRadius: 3,
    padding: 2,
  },
  closeIconView: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  subImgTitleView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  restImgView: {
    width: 78,
    height: 78,
    borderRadius: 39,
    overflow: 'hidden',
  },
  restImg: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  titleText: {
    color: '#000000',
    fontSize: 18,
    fontFamily: 'GilroyBold',
    textAlign: 'center',
  },
  btnView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#F2F2F2',
    borderRadius: 44,
    border: '4px solid #F2F2F2',
    height: 50,
    '& .MuiTabs-indicator': {
      position: 'absolute',
      height: '100%',
      backgroundColor: THEME_COLOR,
      borderRadius: 44,
      zIndex: 1,
    },
    '& .MuiButtonBase-root': {
      maxWidth: '50%',
      minWidth: '50%',
    },
  },
  btnText: {
    color: '#000000',
    fontSize: 14,
    fontFamily: 'GilroyBold',
    textAlign: 'center',
    textTransform: 'capitalize',
    zIndex: 2,
    '&.Mui-selected': {
      color: '#000',
    },
  }
};
