import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import {
	useStripe,
	useElements,
	ExpressCheckoutElement,
} from "@stripe/react-stripe-js";

const ExpressBlock = ({
	amount,
	onClickExpressCheckout,
	onCustomerInfoChange,
	paymentIntentId,
	clientSecret,
	name,
	email,
	setErrorMessage,
	setIsRetry,
	onSuccess,
	isFinalLoading,
	setFinalLoading,
}) => {
	const stripe = useStripe();
	const elements = useElements();

	const [isProcessing, setIsProcessing] = React.useState(false);

	const handleExpressCheckoutConfirm = useCallback(async () => {
		if (isFinalLoading) return;
		setFinalLoading(true);

		if (!stripe || !elements || !paymentIntentId) {
			console.error("Stripe.js has not loaded or paymentIntentId is missing.");
			setFinalLoading(true);
			return;
		}

		setIsProcessing(true);

		try {
			await onCustomerInfoChange(name, email);

			const { error, paymentIntent } = await stripe.confirmPayment({
				elements,
				clientSecret,
				confirmParams: {
					return_url: `${window.location.origin}/completion`,
					payment_method_data: {
						billing_details: {
							name,
							email,
						},
					},
				},
				redirect: "if_required",
			});

			if (error) {
				console.error("Error during payment confirmation:", error);
				setErrorMessage(error.message);
				setIsProcessing(false);
				setFinalLoading(false);
				return;
			}

			if (paymentIntent.status === "succeeded") {

				onSuccess(paymentIntent.id);
			}
		} catch (error) {
			console.error("Error during payment confirmation:", error);
			setErrorMessage(error.message);
			setIsProcessing(false);
			setFinalLoading(false);
		}
	}, [
		stripe,
		elements,
		paymentIntentId,
		clientSecret,
		name,
		email,
		onCustomerInfoChange,
		setErrorMessage,
		setIsProcessing,
		setFinalLoading,
		onSuccess,
		isFinalLoading,
	]);

	useEffect(() => {
		if (!stripe || !elements) {
			setFinalLoading(true);
		}
	}, [stripe, elements, setFinalLoading]);

	const handleExpressCheckoutOnReady = useCallback(() => {
		setFinalLoading(false);
	}, [setFinalLoading]);

	return (
		<Box>
			<Typography
				variant='h6'
				gutterBottom
				sx={{
					mt: "64px",
					textAlign: "center",
					mb: 2,
				}}
			>
				Total: {amount}
			</Typography>

			<Box mb={2}>
				<ExpressCheckoutElement
					onReady={handleExpressCheckoutOnReady}
					onClick={onClickExpressCheckout}
					onCancel={() => setFinalLoading(false)}
					onConfirm={handleExpressCheckoutConfirm}
					options={{
						layout: {
							overflow: "never",
						},
						buttonType: {
							googlePay: "pay",
						},
						buttonHeight: 48,
					}}
				/>
				
			</Box>
		</Box>
	);
};

export default ExpressBlock;
