import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ARWebCartMenuItem from "./ARWebCartMenuItem";

import "./../assets/css/custom.css";

import { ReactComponent as DeliveryFastIcon } from "../assets/images/delivery_fast.svg";

import i18next from "../constants/i18n";

import { TABLE } from "../constants";
import { getOrdersWithTotalPrice } from "../constants/commonFunc";

const ARWebCart = (props) => {
  let { cartItems, totalCartPrice, totalCartPriceUnit } = props;

  const totalSubPrice = useMemo(() => {
    return getOrdersWithTotalPrice(cartItems, [])?.totalPrice?.toFixed(2) ?? 0;
  }, [cartItems]);

  return (
    <Box
      sx={{
        ...styles.dataView,
        minHeight: props.page == "cart" ? window.innerHeight - 260 : 50,
      }}
    >
      <Box sx={styles.cartItemListView}>
        {cartItems.map((x, i) => {
          return (
            <ARWebCartMenuItem
              key={i}
              item={x}
              locale={props.locale}
              editable={props.page == "cart"}
              onAddSubMenu={props.onAddSubMenuItemToCart}
            />
          );
        })}
      </Box>
      {props.orderType != TABLE ? (
        <Box mt={2} mb={3} sx={styles.deliveryDataView}>
          <DeliveryFastIcon
            width={20}
            height={20}
            style={{ transform: props.locale == "ar" ? "scaleX(-1)" : "unset" }}
          />
          <Typography
            ml={props.locale == "ar" ? "unset" : 0.6}
            mr={props.locale == "ar" ? 0.6 : "unset"}
            sx={styles.deliveryTimeText}
          >
            Arrives by 20 minutes
          </Typography>
        </Box>
      ) : (
        <Box mb={3} />
      )}
      <Box>
        <Box sx={styles.summaryView}>
          <Typography sx={styles.orderSummaryText}>
            {i18next.t("Order Summary")}
          </Typography>
          <Box sx={styles.summarySubView}>
            <Box sx={styles.summaryLabelView}>
              <Typography sx={styles.summaryLabelText}>
                {i18next.t("Subtotal")}
              </Typography>
            </Box>
            <Typography sx={styles.summaryValueText}>
              {totalCartPriceUnit}
              {totalSubPrice}
            </Typography>
          </Box>
          <Box sx={styles.summarySubView}>
            <Box sx={styles.summaryLabelView}>
              <Typography sx={styles.summaryLabelText}>
                {i18next.t("Shipping")}
              </Typography>
            </Box>
            <Typography sx={styles.summaryValueText}>
              {totalCartPriceUnit}
              0.00
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.summarySubView}>
          <Box sx={styles.totalLabelView}>
            <Typography sx={styles.totalLabelText}>
              {i18next.t("Total")}
            </Typography>
          </Box>
          <Typography sx={styles.totalValueText}>
            {totalCartPriceUnit}
            {totalCartPrice}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ARWebCart;

const styles = {
  dataView: {
    position: "relative",
    backgroundColor: "#FFF",
    px: 2,
    pt: 2,
    pb: 2,
  },
  cartItemListView: {
    borderBottom: "1px solid #EEEFF0",
    pb: 2,
  },
  deliveryDataView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#FFF9DB",
    padding: 2,
  },
  deliveryTimeText: {
    color: "#495057",
    fontSize: 13,
    fontFamily: "GilroySemiBold",
  },
  summaryView: {
    borderBottom: "1px solid #EEEFF0",
    pb: 2,
  },
  orderSummaryText: {
    color: "#000000",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
  },
  summarySubView: {
    display: "flex",
    flexDirection: "row",
    mt: 1.5,
  },
  summaryLabelView: {
    width: "100%",
  },
  summaryLabelText: {
    color: "#212529",
    fontSize: 13,
    fontFamily: "GilroyMedium",
  },
  summaryValueText: {
    color: "#212529",
    fontSize: 13,
    fontFamily: "GilroyMedium",
  },
  totalLabelView: {
    width: "100%",
  },
  totalLabelText: {
    color: "#212529",
    fontSize: 16,
    fontFamily: "GilroySemiBold",
  },
  totalValueText: {
    color: "#212529",
    fontSize: 18,
    fontFamily: "GilroyMedium",
  },
};
