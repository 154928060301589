import * as React from 'react';
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import {LOADING_COLOR} from "../constants";

const theme = createTheme({
  palette: {
    loading_color: {
      main: LOADING_COLOR,
    },
  },
});

const Loader = (props) => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={props.height}>
      <ThemeProvider theme={theme}>
        <CircularProgress color="loading_color"/>
      </ThemeProvider>
    </Grid>
  );
};

export default Loader;
