import * as React from 'react';
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

let circle_size = window.innerWidth <= 400 ? (window.innerWidth / 3) - 64 : 60;
let next_prev_size = 32;

const AppNextBtn = (props) => {
  return (
    <>
      <Box sx={styles.prevNextImgMainView}
           onClick={() => props.onNextBtnClick()}>
        <Box sx={styles.prevNextImgView}>
          <img alt="" src={props.image} style={styles.prevNextImg}/>
        </Box>
      </Box>
      <Box sx={[styles.nextView, {transform: props.locale == 'ar' ? "scaleX(-1)" : 'unset',
        marginLeft: props.locale == 'ar' ? 'unset' : -1.8,
        marginRight: props.locale == 'ar' ? -1.8 : 'unset'
      }]}
           dir={'ltr'}
           onClick={() => props.onNextBtnClick()}>
        <Typography
          sx={[styles.nextText, {transform: props.locale == 'ar' ? "scaleX(-1)" : 'unset'}]}>NEXT</Typography>
      </Box>
    </>
  );
};

export default AppNextBtn;

const styles = {
  prevNextImgMainView: {
    width: circle_size,
    height: circle_size,
    borderRadius: circle_size / 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 227, 66, 1)',
    zIndex: 5,
  },
  prevNextImgView: {
    width: circle_size - 10,
    height: circle_size - 10,
  },
  prevNextImg: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    cursor: 'pointer',
    objectFit: 'cover',
  },
  nextView: {
    backgroundColor: 'rgba(255, 227, 66, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: next_prev_size + 5,
    height: next_prev_size,
    pr: 1.2,
    borderTopRightRadius: next_prev_size / 2,
    borderBottomRightRadius: next_prev_size / 2,
    zIndex: 4,
  },
  nextText: {
    fontSize: 9.2,
    fontFamily: 'GilroyBold',
    cursor: 'pointer',
  },
}
