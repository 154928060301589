import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import './../assets/css/custom.css';
import i18next from "../constants/i18n";

const ARWebCartFooter = (props) => {
  let {btnText, totalCartPrice, totalCartPriceUnit} = props;

  return (
    <Box px={2} sx={{...styles.bottomView, zIndex: props.zIndex}}>
      <Box py={0.5} sx={styles.totalMainView}>
        <Box sx={styles.totalSubView}>
          <Typography sx={styles.totalTitleText}>{props.totalText}</Typography>
          <Typography sx={styles.totalValText}>{totalCartPriceUnit}{totalCartPrice}</Typography>
        </Box>
      </Box>
      {props.cartItemsLength > 0 ?
        <Box sx={{
          ...styles.orderNowBtnView,
          backgroundColor: props.btnEnable ? '#FFF' : '#AAA',
          cursor: props.btnEnable ? 'pointer' : 'default',
        }}
             onClick={props.btnEnable ? props.onBtnSubmit : () => null}>
          <Typography sx={styles.orderNowBtnText}>{btnText}</Typography>
        </Box> : null}
    </Box>
  )
}

export default ARWebCartFooter;

const styles = {
  bottomView: {
    height: 74,
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#000',
    paddingVertical: '5px',
    bottom: 0,
    left: 0,
    right: 0,
  },
  totalMainView: {
    display: 'flex',
    flexDirection: 'row',
  },
  totalSubView: {},
  totalTitleText: {
    color: '#FFF',
    fontSize: 13,
    fontFamily: 'GilroyRegular',
  },
  totalValText: {
    color: '#FFF',
    fontSize: 22,
    fontFamily: 'GilroySemiBold',
    lineHeight: 1,
  },
  orderNowBtnView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 150,
    height: 50,
    borderRadius: 44
  },
  orderNowBtnText: {
    color: '#000000',
    fontSize: 14,
    fontFamily: 'GilroyBold',
    textAlign: 'center',
  },
};
