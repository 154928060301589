import React, { useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import ARWebAddSubMenuBtn from "./ARWebAddSubMenuBtn";

import "./../assets/css/custom.css";

import { CURRENCY_SYMBOL, THEME_COLOR } from "../constants";

import { ReactComponent as MenuImg } from "../assets/images/file1.svg";

import { getCartItemsWithTotalPrice } from "../constants/commonFunc";

const ARWebCartMenuItem = (props) => {
  let { item } = props;

  const hideMenuThumbNail = item?.extras?.hideMenuThumbNailImages;

  let counts = {};
  if (item?.selectedOptions?.length) {
    let arr = item.selectedOptions.reduce((a, b) => a.concat(b), []);
    arr.forEach((x) => (counts[x.item] = (counts[x.item] || 0) + 1));
  }

  const totalPrice = useMemo(() => {
    return getCartItemsWithTotalPrice(item, [])?.totalPrice?.toFixed(2) ?? 0;
  }, [item?.count]);

  return (
    <Grid item xs={12} container direction="row" sx={styles.menuDataView}>
      <Grid
        pr={hideMenuThumbNail ? 0 : props.locale == "ar" ? "unset" : 2}
        pl={props.locale == "ar" ? 2 : "unset"}
        style={{ display: "flex" }}
      >
        {!hideMenuThumbNail ? (
          item?.images?.length ? (
            <Box sx={styles.menuImage}>
              <img
                src={item.images[0]}
                width={"100%"}
                height={"100%"}
                style={styles.menuImageStyle}
              />
            </Box>
          ) : (
            <Box
              sx={{
                ...styles.menuImage,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MenuImg width={"40%"} height={"40%"} />
            </Box>
          )
        ) : null}
      </Grid>
      <Grid style={styles.dataView}>
        <Box style={styles.subDataView}>
          <Box sx={{ width: "100%" }}>
            <Typography className="first-char-capital" sx={styles.menuName}>
              {item?.name}
            </Typography>
            {item?.calories ? (
              <Box sx={styles.kcalMainView}>
                <Box sx={styles.dotText}></Box>
                <Box ml={0.5} sx={styles.kcalTextView} dir={"ltr"}>
                  <Typography sx={styles.kcalText}>
                    {item?.calories ? item.calories : 0} kcal
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box mb={1} />
            )}
            <Box sx={{ display: "flex" }}>
              <Box sx={styles.menuPriceView}>
                <Typography sx={styles.menuPrice}>
                  {CURRENCY_SYMBOL?.[item?.price?.currency]}
                  {totalPrice}
                </Typography>
              </Box>
            </Box>
          </Box>
          {props.editable ? (
            <ARWebAddSubMenuBtn
              count={item?.count ? item.count : 1}
              onAddSubMenu={(type) => props.onAddSubMenu(type, item)}
            />
          ) : null}
        </Box>
        {item?.selectedOptions?.length ? (
          <Box
            mt={1}
            sx={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            {Object.keys(counts).map((key, index) => {
              return (
                <Typography key={index} sx={styles.noteText}>
                  {counts[key]} with {key}
                </Typography>
              );
            })}
          </Box>
        ) : null}
        {item?.note ? (
          <Box mt={1} sx={{ display: "flex" }}>
            <Typography sx={styles.noteText}>Note: {item.note}</Typography>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ARWebCartMenuItem;

const styles = {
  menuDataView: {
    flexWrap: "nowrap",
    pt: 2,
    pb: 2,
  },
  menuImage: {
    position: "relative",
    backgroundColor: "#EEE",
    width: { xs: 70, md: 105 },
    height: { xs: 64, md: 90 },
    borderRadius: 3,
    overflow: "hidden",
  },
  menuImageStyle: {
    objectFit: "cover",
  },
  dataView: {
    width: "100%",
  },
  subDataView: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  menuName: {
    fontSize: 15,
    fontFamily: "GilroyBold",
    wordBreak: "break-word",
    lineHeight: "1.2",
  },
  menuPriceView: {
    backgroundColor: THEME_COLOR,
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 54,
    height: 25,
    borderRadius: 20,
    px: "10px",
  },
  menuPrice: {
    fontSize: 14,
    fontFamily: "GilroyBold",
    textAlign: "center",
  },
  noteText: {
    color: "#000",
    fontSize: 12,
    fontFamily: "GilroySemiBold",
  },
  kcalMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mb: 1,
  },
  kcalTextView: {},
  dotText: {
    backgroundColor: "#D9D9D9",
    width: 6,
    height: 6,
    borderRadius: 3,
  },
  kcalText: {
    color: "#555",
    fontSize: 13,
    fontFamily: "GilroyMedium",
  },
};
