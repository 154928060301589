import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// import styles from "../components/YourBillWidget.module.css";

// import "./../assets/css/custom.css";

import i18next from "../../../constants/i18n";
import { formatCurrency } from "../../../utils/utils";

let fontStyle1 = {
	temp1: {},
	temp2: {
		fontFamily: "GilroySemiBold !important",
	},
};

const YourBillWidget = (props) => {
	let { totalCartPrice, subTotal, discount, taxValue, tipValue, restTemplate, serviceCharge } = props;

	let billing_list = [
		{
			label: i18next.t("Subtotal"),
			value: subTotal,
		},
		{
			label: i18next.t("Discount"),
			value: discount,
		},
		{
			label: i18next.t("Tax"),
			value: taxValue,
		},
		{
		  label: i18next.t("Service Fee"),
		  value: serviceCharge,
		},
    {
		  label: i18next.t("Tip"),
		  value: tipValue,
		},
	];

	return (
		<Grid item mx={props.page == "checkout" ? 2 : 1} pb={3} mt={1.5}>
			<Box
				sx={{
					p: 2,
					border: "1px solid #F2F2F3",
					borderRadius: "16px",
					boxShadow: "0px 1px 3px 0px #00000040",
					background: `linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.4),
                        rgba(255, 255, 255, 0.4)
                      ),
                      linear-gradient(
                        270deg,
                        #F2F2F3 -37.9%,
                        rgba(255, 255, 255, 0.37) 5.96%,
                        rgba(255, 255, 255, 0) 111.15%
                      )`,
				}}
			>
				{billing_list.map((infoRow, i) => {
					if (infoRow.value === 0) {
						return null;
					}
					return (
						<Box
							key={i}
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								mb: 1,
							}}
						>
							<Box
								sx={{
									flex: 1,
									display: "flex",
								}}
							>
								<Typography
									sx={{
										color: "#000000",
										fontSize: 16,
										fontFamily: "GilroyMedium",
									}}
								>
									{infoRow.label}
								</Typography>
							</Box>
							<Typography
								sx={{
									color: "#000000",
									fontSize: "16px !important",
									fontFamily: "Inter",
								}}
							>
								{typeof infoRow?.value === "number"
									? formatCurrency(infoRow.value)
									: infoRow.value}
							</Typography>
						</Box>
					);
				})}

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
                        mt: 4,
                        mb: 2
					}}
				>
					<Box
						sx={{
							flex: 1,
							display: "flex",
						}}
					>
						<Typography
							sx={{
								color: "#000000",
								fontSize: "23px !important",
								fontFamily: "GilroyBold !important",
                                lineHeight: 1
							}}
						>
							{i18next.t("Your bill")}
						</Typography>
					</Box>
					<Typography
						sx={{
							color: "#000000",
							fontSize: "20px !important",
							fontFamily: "GilroyBold !important",
                            lineHeight: 1
						}}
					>
						{typeof totalCartPrice === "number"
							? formatCurrency(totalCartPrice)
							: totalCartPrice}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default YourBillWidget;
