import React, {useRef} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import './../assets/css/custom.css';

const ARWebAddSubMenuBtn = (props) => {
  let minWidth = props?.minWidth ? props.minWidth : 97;
  let circleSize = props?.circleSize ? props.circleSize : 26;
  let circleBg = props?.circleBg ? props.circleBg : '#EFEFEF';
  let addSubFontSize = props?.addSubFontSize ? props.addSubFontSize : 14;
  let addSubFontColor = props?.addSubFontColor ? props.addSubFontColor : '#000000';

  let circleStyle = {
    width: circleSize,
    height: circleSize,
    borderRadius: circleSize / 2,
    backgroundColor: circleBg,
  };

  let circleFontStyle = {
    fontSize: addSubFontSize,
    color: addSubFontColor,
  };

  return (
    <Box sx={{...styles.mainView, minWidth: minWidth}}>
      <Box sx={{...styles.addSubView, ...circleStyle}} onClick={() => props.onAddSubMenu('-')}>
        <Typography sx={{...styles.addSubText, ...circleFontStyle}}>-</Typography>
      </Box>
      <Box>
        <Typography sx={styles.countText}>{props.count}</Typography>
      </Box>
      <Box sx={{...styles.addSubView, ...circleStyle}} onClick={() => props.onAddSubMenu('+')}>
        <Typography sx={{...styles.addSubText, ...circleFontStyle}}>+</Typography>
      </Box>
    </Box>
  );
}

export default ARWebAddSubMenuBtn;

const styles = {
  mainView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #EFEFEF',
    maxWidth: 160,
    borderRadius: 21,
    padding: '3px',
  },
  addSubView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  countText: {
    color: '#000000',
    fontSize: 14,
    fontFamily: 'GilroyBold',
    textAlign: 'center',
  },
  addSubText: {
    fontFamily: 'GilroyBold',
    textAlign: 'center',
  }
};
