import React from "react";

import {
  SIDEBAR_ICON_SIZE,
  SIDEBAR_MENU_POSITION as SIDEBAR_MENU_POSITION2,
} from "./RestaurantTemplate2";

import {
  SIDEBAR_ICON_SIZE as SIDEBAR_ICON_SIZE2,
  SIDEBAR_MENU_POSITION as SIDEBAR_MENU_POSITION1,
} from "./RestaurantTemplate1";

import { ReactComponent as ViewArIcon1 } from "../assets/images/view_ar.svg";
import { ReactComponent as ViewArIcon2 } from "../assets/images/view_ar3.svg";

const ArBtn = (props) => {
  let { canActivateAr, restTemplate, restaurantId, userData } = props;

  let ViewArIcon = {
    temp1: (
      <ViewArIcon1 width={SIDEBAR_ICON_SIZE2} height={SIDEBAR_ICON_SIZE2} />
    ),
    temp2: <ViewArIcon2 width={SIDEBAR_ICON_SIZE} height={SIDEBAR_ICON_SIZE} />,
  };

  let menu_position = SIDEBAR_MENU_POSITION1;
  let showVrScreenModal = false;
  // if (restTemplate == "temp2") {
  //   menu_position = SIDEBAR_MENU_POSITION2;
  //   if (userData == null) {
  //     showVrScreenModal = true;
  //   } else {
  //     if (restaurantId == userData?._idRestaurant) {
  //       showVrScreenModal = false;
  //     } else {
  //       showVrScreenModal = true;
  //     }
  //   }
  // }

  let arLogoStyle = {
    cursor: "pointer",
    marginBottom: `${menu_position}px`,
    lineHeight: 0,
  };

  return (
    // canActivateAr ? (
    canActivateAr ? (
      <div
        onClick={() =>
          showVrScreenModal
            ? props.setVrScreenModalVisible(true)
            : props.onArIconClick()
        }
        style={arLogoStyle}
      >
        {ViewArIcon[restTemplate]}
      </div>
    ) : null
    // ) : (
    //   <>
    //     <a
    //       onClick={() =>
    //         showVrScreenModal
    //           ? props.setVrScreenModalVisible(true)
    //           : props.setEnterAframeAr(true)
    //       }
    //       style={arLogoStyle}
    //     >
    //       {ViewArIcon[restTemplate]}
    //     </a>
    //   </>
    // )
  );
};

export default ArBtn;
