import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./MenuListAddSubMenuBtn.module.css";

const MenuListAddSubMenuBtn = (props) => {
  const { theme = "theme1" } = props;

  const handlerOnAddMenu = (e) => {
    e.stopPropagation();
    props.onAddSubMenu("+");
  };

  const handlerOnSubMenu = (e) => {
    e.stopPropagation();
    props.onAddSubMenu("-");
  };

  return (
    <Box
      className={`${styles?.["main-view"]} ${
        styles?.[theme === "theme1" ? "theme1-view" : "theme2-view"]
      }`}
      sx={{
        width: props?.inputWidth ? props.inputWidth : 100,
        height: props?.inputHeight ? props.inputHeight : 35,
      }}
    >
      <Box
        className={styles.addSubView}
        onClick={handlerOnSubMenu}
        sx={{ width: props?.addSubWidth ? props.addSubWidth : 40 }}
      >
        <Typography className={`${styles?.["add-sub-text"]}`}>-</Typography>
      </Box>
      <Box>
        <Typography className={`${styles?.["count-text"]}`}>
          {props.count}
        </Typography>
      </Box>
      <Box
        className={styles.addSubView}
        onClick={handlerOnAddMenu}
        sx={{ width: props?.addSubWidth ? props.addSubWidth : 40 }}
      >
        <Typography className={`${styles?.["add-sub-text"]}`}>+</Typography>
      </Box>
    </Box>
  );
};

export default MenuListAddSubMenuBtn;
