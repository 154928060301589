import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";

const CheckoutHeader = ({
	headerTitle = "Payment",
	disableBackButton = false,
	onBackButtonClick,
}) => {
	const navigate = useNavigate();
	const handleBackToCartOnClick = () => {
		onBackButtonClick ? onBackButtonClick() : navigate(-1);
	};
	return (
		<Box
			sx={{
				height: "84px",
				backgroundColor: "#ffffff",
				color: "black",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
				position: "relative",
			}}
		>
			{!disableBackButton && (
				<IconButton
					sx={{
						position: "absolute",
						left: "16px",
						color: "black",
						backgroundColor: "rgba(255, 255, 255, 0.1)",
						height: "40px",
						width: "40px",
					}}
					onClick={handleBackToCartOnClick}
				>
					<ChevronLeft />
				</IconButton>
			)}
			<Typography
				variant='h5'
				sx={{
					fontWeight: 700,
					fontSize: 18,
					fontFamily: "Inter",
				}}
			>
				{headerTitle}
			</Typography>
		</Box>
	);
};

export default CheckoutHeader;
