import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { connect } from "react-redux";
import { updateUserData } from "../redux/actions/restaurantDataActions";

import * as Api from "../api/app";

import VrScreenTextInput from "./VrScreenTextInput";
import VrScreenDatePicker from "./VrScreenDatePicker";
import AlertMsg from "./AlertMsg";

import { ReactComponent as ArLogo } from "../assets/images/ar.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/profile.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as DobIcon } from "../assets/images/dob.svg";

import styles from "./VrScreenModal.module.css";

const VrScreenModal = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phNo, setPhNo] = useState("");
  const [dob, setDob] = useState("");
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let { restaurantName, restaurantData } = props;
  let {
    modalBgColor,
    modalFontColor,
    userFormDataBg,
    userFormDataLabelColor,
    userFormDataFontColor,
    buttonBg,
    buttonTextBg,
  } = restaurantData;

  useEffect(() => {
    setModalVisible(props.visible);
    if (props.visible) {
      setName("");
      setPhNo("");
      setDob("");
      getCurrentLocation();
    }
  }, [props.visible]);

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      setMsgAlert({
        open: true,
        message: "Geolocation is not supported by your browser",
        msgType: "error",
      });
    } else {
      // setIsLoading(true);
      console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          getUserLocation(position.coords);
        },
        () => {
          // setIsLoading(false);
          console.log("Unable to retrieve your location");
          setMsgAlert({
            open: true,
            message: "Unable to retrieve your location",
            msgType: "error",
          });
        }
      );
    }
  };

  const getUserLocation = (position) => {
    let locationResponse = "";
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        position.latitude +
        "," +
        position.longitude +
        "&key=" +
        process.env.REACT_APP_MAP_API_KEY
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let country_val = "";
        locationResponse = responseJson.results[0].address_components;
        locationResponse.map((y, i) => {
          if (y.types.indexOf("country") > -1) {
            console.log("y", y);
            country_val = y.short_name;
          }
        });
        if (country_val) {
          setCountryCode(country_val.toLowerCase());
        }
        // setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        // setIsLoading(false);
        setMsgAlert({
          open: true,
          message: "Location fetch failed",
          msgType: "error",
        });
      });
  };

  const onSubmit = () => {
    let err = null;
    if (name == "") {
      err = { name: "Name is required" };
    }

    if (phNo.length < 5) {
      err = { ...err, phNo: "Invalid Phone Number" };
    }

    if (err != null) {
      setError(err);
      return;
    }

    let data = {
      _idRestaurant: props.restaurantId,
      name: name,
      phoneNumber: `+${phNo}`,
    };

    if (dob) {
      data = { ...data, dateofBirth: dayjs(dob).format("YYYY-MM-DD") };
    }

    let data1 = JSON.stringify(data);
    Api.addUserEnquiries(data1).then((response) => {
      if (response.success) {
        props.updateUserData(response.data);
        props.onSubmit();
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  let modal_bg = modalBgColor
    ? modalBgColor.length == 4
      ? `${modalBgColor}${modalBgColor.substring(1)}80`
      : `${modalBgColor}80`
    : "transparent";

  let user_form_data_bg = userFormDataBg
    ? userFormDataBg.length == 4
      ? `${userFormDataBg}${userFormDataBg.substring(1)}CC`
      : `${userFormDataBg}CC`
    : "#CA7128CC";

  let inputLabelStyle = {
    color: userFormDataLabelColor ? userFormDataLabelColor : "#FFF",
    fontSize: 11,
    fontFamily: "BauhausLight",
    marginLeft: "45px",
    marginBottom: "5px",
  };

  return (
    <SwipeableDrawer
      sx={{
        "& .MuiPaper-root": {
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          backgroundColor: "transparent",
          overflow: "unset",
        },
      }}
      anchor={"bottom"}
      open={modalVisible}
      onClose={props.onClose}
      disableSwipeToOpen
      transitionDuration={{
        enter: 300,
        exit: 350,
      }}
    >
      <Box
        className={styles.mainDialogView}
        sx={{
          backgroundColor: modal_bg,
          backdropFilter: "blur(10px)",
        }}
        py={2}
        px={2.5}
      >
        <Box className={styles.bgShade} />
        <Box className={styles.contentView} mt={4}>
          <Box mx={1} mb={4}>
            <Typography
              className={styles.desc1}
              sx={{ color: modalFontColor ? modalFontColor : "#ffffff" }}
            >
              READY TO DIVE INTO THE
            </Typography>
            <Typography
              className={styles.desc2}
              sx={{ color: modalFontColor ? modalFontColor : "#ffffff" }}
            >
              Culinary Magic?
            </Typography>
          </Box>
          <Box>
            <Typography
              mx={1}
              className={styles.desc3}
              sx={{ color: modalFontColor ? modalFontColor : "#ffffff" }}
            >
              Come be a part of the{" "}
              <span
                className={styles.restNameText}
                style={{ color: modalFontColor ? modalFontColor : "#ffffff" }}
              >
                {restaurantName}
              </span>
            </Typography>
            <Box
              my={1.5}
              className={styles.inputView}
              sx={{
                backgroundColor: user_form_data_bg,
              }}
              p={2}
            >
              <Box sx={{ width: "100%" }}>
                <VrScreenTextInput
                  label={"Your name, please?"}
                  icon={<ProfileIcon width={30} height={30} />}
                  value={name}
                  setValue={setName}
                  labelColor={
                    userFormDataLabelColor ? userFormDataLabelColor : "#FFF"
                  }
                  inputColor={
                    userFormDataFontColor ? userFormDataFontColor : "#FFF"
                  }
                  error={error?.name}
                />
              </Box>
              <Box mt={2} sx={{ width: "100%" }}>
                <Typography sx={inputLabelStyle}>
                  How can we Stay Connected?
                </Typography>
                <PhoneInput
                  country={countryCode}
                  value={phNo ? phNo : null}
                  onChange={setPhNo}
                  autoFormat={false}
                  // countryCodeEditable={false}
                  disableCountryCode={false}
                  enableSearch={true}
                  disableSearchIcon={true}
                  placeholder={""}
                  containerClass={`vr-screen-modal-phcode ${
                    phNo == "" && countryCode == ""
                      ? "vr-screen-modal-no-value"
                      : ""
                  }`}
                  isValid={(value, country, countries) => {
                    if (value != "") {
                      let filter = countries.filter(
                        (item) =>
                          item.dialCode.startsWith(value) ||
                          value.startsWith(item.dialCode)
                      );
                      if (filter.length == 0) {
                        return "Invalid Dial Code";
                      }
                    }
                  }}
                  renderStringAsFlag={
                    phNo == "" && countryCode == "" ? (
                      <PhoneIcon width={30} height={30} />
                    ) : null
                  }
                  buttonStyle={{
                    backgroundColor: "transparent",
                    border: "0px",
                  }}
                  inputStyle={{
                    width: "-webkit-fill-available",
                    height: 23,
                    color: userFormDataFontColor
                      ? userFormDataFontColor
                      : "#FFF",
                    fontSize: 15,
                    fontFamily: "BauhausMedium",
                    background: "transparent",
                    border: 0,
                    marginLeft: 45,
                    paddingLeft: 0,
                    borderBottom: "1px solid #FFF",
                    borderRadius: 0,
                    paddingBottom: 5,
                  }}
                  containerStyle={{
                    height: 23,
                  }}
                  dropdownStyle={{
                    color: "#000",
                    fontSize: 15,
                    fontFamily: "BauhausMedium",
                  }}
                  searchStyle={{
                    color: "#000",
                    fontSize: 15,
                    fontFamily: "BauhausMedium",
                    width: "93%",
                  }}
                />
                {error?.phNo ? (
                  <Typography className={styles.error} ml={"45px"}>
                    {error?.phNo}
                  </Typography>
                ) : null}
              </Box>
              <Box my={2} sx={{ width: "100%" }}>
                <VrScreenDatePicker
                  value={dob}
                  inputFormat="DD/MM/YYYY"
                  maxDate={dayjs().endOf("year").subtract(15, "year")}
                  onChange={(newValue) => {
                    setDob(newValue);
                  }}
                  labelColor={
                    userFormDataLabelColor ? userFormDataLabelColor : "#FFF"
                  }
                  inputColor={
                    userFormDataFontColor ? userFormDataFontColor : "#FFF"
                  }
                  label={"Date of Birth (DD/MM/YYYY)"}
                  icon={<DobIcon width={30} height={30} />}
                  error={error?.dob}
                />
              </Box>
            </Box>
          </Box>

          <Box className={styles.btnMainView} mt={2} mb={4}>
            <Box
              className={styles.btnView}
              sx={{
                backgroundColor: buttonBg ? buttonBg : "#ffffff",
              }}
              onClick={onSubmit}
            >
              <Typography
                className={styles.btnText}
                sx={{
                  color: buttonTextBg ? buttonTextBg : "#d87025",
                }}
              >
                Lets Experience
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </SwipeableDrawer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (data) => dispatch(updateUserData(data)),
  };
};

export default connect(null, mapDispatchToProps)(VrScreenModal);
