import * as actionTypes from "../actions/types";

const initialState = {
  restData: null,
  tableData: null,
  categoryList: [],
  menuList: [],
  modelList: [],
  courseList: [],
};

const apiDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_REST_DATA:
      return {
        ...state,
        restData: action.payload,
      };

    case actionTypes.UPDATE_TABLE_DATA:
      return {
        ...state,
        tableData: action.payload,
      };

    case actionTypes.UPDATE_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };

    case actionTypes.UPDATE_MENU_LIST:
      return {
        ...state,
        menuList: action.payload,
      };

    case actionTypes.UPDATE_MODEL_LIST:
      return {
        ...state,
        modelList: action.payload,
      };

    case actionTypes.UPDATE_COURSE_LIST:
      return {
        ...state,
        courseList: action.payload,
      };
  }
  return state;
};

export default apiDataReducer;
