import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import styles from "./TableOrderPayNow.module.css";

import i18next from "../constants/i18n";

const TableOrderPayNow = (props) => {
  return (
    <Grid px={2} className={styles.mainView}>
      <Box className={styles.topView}>
        <Typography pt={15} className={styles.orderText}>
          Preparing Your Order
        </Typography>
      </Box>
      <Box mb={3} className={styles.btnView}>
        <Button
          type="submit"
          className={styles.addMoreBtn}
          onClick={props.onAddMore}
        >
          {i18next.t("Add More")}
        </Button>
        {props.onlineTablePayment ? (
          <Button
            type="button"
            className={styles.payNow}
            onClick={props.onPayNow}
          >
            {i18next.t("Pay Now")}
          </Button>
        ) : null}
      </Box>
    </Grid>
  );
};

export default TableOrderPayNow;
