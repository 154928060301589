import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Loader from "../components/Loader";
import AppNextPrevBtnTemp1 from "./AppNextPrevBtnTemp1";
import ArBtn from "./ArBtn";

import { CURRENCY_SYMBOL, THEME_COLOR } from "../constants";

import { ReactComponent as LeftWhiteArrow } from "../assets/images/left_white_arrow.svg";
import { ReactComponent as ShareIcon } from "../assets/images/share.svg";
import { ReactComponent as ViewDetailIcon } from "../assets/images/view_detail.svg";
import { ReactComponent as WifiIcon } from "../assets/images/wifi.svg";

import BgImg from "../assets/images/bg.jpg";

import "../assets/css/custom.css";
import { getRequiredModifierPrice } from "../constants/commonFunc";

const TOP_VIEW_HEIGHT = 70;
const symbol = CURRENCY_SYMBOL;
const social_icon_size = 20;

export let SIDEBAR_MENU_POSITION = 10;
export let SIDEBAR_ICON_SIZE = 46;

const RestaurantTemplate1 = (props) => {
  const {
    restaurantId,
    restaurantName,
    restaurantImg,
    restaurantLogo,
    restaurantData,
    restTemplate,
    viewArBtnTop,
    menuList,
    menuIndex,
    menuFullList,
    enterAframeAr,
    enterWebXrArMode,
    canActivateAr,
    showPrevBtn,
    showNextBtn,
  } = props;

  const { menuNameRef, progressCircleRef } = props;

  const menu = menuList?.[menuIndex];
  const currency = symbol?.[menu?.price?.currency];
  const itemPrice = (
    menu?.price?.value
      ? menu.price.value
      : getRequiredModifierPrice(menu?.modifiers, menuFullList)
  )?.toFixed(2);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handlerOnShareBtnClick = () => {
    props.onShareBtnClick(menu);
  };

  const handlerOnSetMenuModelData = () => {
    props.onSetMenuModelData(menu);
  };

  const NextPrevBtnView = () => {
    return (
      <AppNextPrevBtnTemp1
        enterAframeAr={enterAframeAr}
        enterWebXrArMode={enterWebXrArMode}
        menuIcon={
          restaurantData?.bottomMenuIcon ? restaurantData.bottomMenuIcon : null
        }
        menuList={menuList}
        menuIndex={menuIndex}
        locale={props.locale}
        showPrevBtn={showPrevBtn}
        showNextBtn={showNextBtn}
        onNextBtnClick={props.onNextBtnClick}
        onPrevBtnClick={props.onPrevBtnClick}
        onRestMenuNavigate={props.onRestMenuNavigate}
      />
    );
  };

  return (
    <>
      <Grid
        dir={"ltr"}
        container
        item
        xs={12}
        direction="row"
        px={2}
        style={{
          ...styles.topMainView,
          backgroundImage: `url(${
            restaurantData?.headerBg ? restaurantData.headerBg : restaurantImg
          })`,
        }}
      >
        <Box className="app-restaurant-bg-shade2" style={styles.bgShadeImage} />
        <Box style={styles.topView}>
          <Box sx={styles.restImgMainView}>
            <Box sx={styles.restImgView}>
              {restaurantLogo ? (
                <img alt="" src={restaurantLogo} style={styles.restImg} />
              ) : null}
            </Box>
          </Box>
          <Box sx={styles.titleView}>
            <Typography sx={styles.titleText}>{restaurantName}</Typography>
            {restaurantData?.address?.city ? (
              <Typography sx={styles.addressText}>
                {restaurantData.address.city}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={styles.contentView}>
        <img
          alt=""
          src={restaurantData?.arModelBg ? restaurantData.arModelBg : BgImg}
          style={styles.bgImage}
        />
        {props.modelAR}
        {/*<Box sx={{...styles.socialIconMainView, top: viewArBtnTop}}>*/}
        {/*  <Box sx={styles.socialIconSubView}>*/}
        {/*    <Box sx={styles.socialIconView}>*/}
        {/*      <SnapchatIcon width={social_icon_size} height={social_icon_size}/>*/}
        {/*    </Box>*/}
        {/*    <Box sx={styles.socialIconView}>*/}
        {/*      <TwitterIcon width={social_icon_size} height={social_icon_size}/>*/}
        {/*    </Box>*/}
        {/*    <Box sx={styles.socialIconView}>*/}
        {/*      <InstagramIcon width={social_icon_size} height={social_icon_size}/>*/}
        {/*    </Box>*/}
        {/*    <Box sx={styles.socialIconView}>*/}
        {/*      <FacebookIcon width={social_icon_size} height={social_icon_size}/>*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        <Box
          style={styles.iconView}
          sx={{
            top: `${viewArBtnTop}px`,
            right: props.locale == "ar" ? "unset" : 10,
            left: props.locale == "ar" ? 10 : "unset",
          }}
        >
          {restaurantData?.socialMedias
            .filter((x) => x?.icon)
            .map((x, i) => {
              let width = x?.data?.iconWidth ?? SIDEBAR_ICON_SIZE;
              let height = x?.data?.iconHeight ?? SIDEBAR_ICON_SIZE;
              return (
                <Box
                  key={i}
                  sx={{
                    ...styles.detailBtn,
                    width: `${width}px`,
                    height: `${height}px`,
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                  onClick={() =>
                    x?.data?.url ? window.open(x?.data?.url, "_blank") : null
                  }
                >
                  <img src={x?.icon} width={"100%"} height={"100%"} />
                </Box>
              );
            })}
          {restaurantData?.wifiAvailable ? (
            <Box sx={styles.detailBtn} onClick={props.onWifiClick}>
              <WifiIcon width={"100%"} height={"100%"} />
            </Box>
          ) : null}
          <ArBtn
            canActivateAr={canActivateAr}
            restTemplate={restTemplate}
            restaurantId={restaurantId}
            userData={props.userData}
            setVrScreenModalVisible={props.setVrScreenModalVisible}
            setEnterAframeAr={props.setEnterAframeAr}
            onArIconClick={props.onArIconClick}
          />
          <Box
            sx={{
              ...styles.detailBtn,
            }}
            onClick={handlerOnShareBtnClick}
          >
            <ShareIcon width={"100%"} height={"100%"} />
          </Box>
          <Box
            sx={{
              ...styles.detailBtn,
            }}
            onClick={handlerOnSetMenuModelData}
          >
            <ViewDetailIcon width={"100%"} height={"100%"} />
          </Box>
        </Box>
        <div className="progress-loader" ref={progressCircleRef}>
          <Loader height={{ height: window.innerHeight }} />
        </div>
        {menuList.length > 0 ? (
          <Grid
            ref={menuNameRef}
            container
            item
            xs={12}
            direction="row"
            px={2}
            sx={styles.menuMainView}
          >
            <Grid item xs={8}>
              <Typography
                className="app-restaurant-menu-title"
                sx={styles.menuTitleView}
              >
                {capitalizeFirstLetter(menu?.name)}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={styles.menuPriceMainView}>
              <Box sx={styles.menuPriceView} dir={"ltr"}>
                <Typography sx={styles.menuPriceText}>
                  {currency} {itemPrice}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : null}
        {enterWebXrArMode ? null : <NextPrevBtnView />}
      </Grid>
    </>
  );
};

export default RestaurantTemplate1;

const styles = {
  mainView: {},
  topMainView: {
    position: "relative",
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "space-between",
    height: TOP_VIEW_HEIGHT,
    flexWrap: "nowrap",
    backgroundSize: "cover",
  },
  topView: {
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  bgShadeImage: {
    backgroundColor: "#000",
    width: "100%",
    height: TOP_VIEW_HEIGHT,
    position: "absolute",
    opacity: 0.4,
    top: 0,
    left: 0,
  },
  backBtn: {
    marginRight: 10,
    zIndex: 3,
  },
  restImgMainView: {
    width: 60,
  },
  restImgView: {
    width: 50,
    height: 50,
    borderRadius: 25,
    overflow: "hidden",
    zIndex: 1,
  },
  restImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  titleView: {
    marginLeft: 1,
    zIndex: 1,
  },
  titleText: {
    color: "#FFF",
    fontSize: 22,
    lineHeight: 1.1,
    fontFamily: "GilroyBold",
    // width: 'min-content',
    textAlign: "center",
    mt: 1,
  },
  addressText: {
    color: "#FFF",
    fontSize: 11,
    lineHeight: 1,
    fontFamily: "GilroyRegular",
    textAlign: "right",
  },
  contentView: {
    position: "relative",
    height: window.innerHeight - TOP_VIEW_HEIGHT,
  },
  socialIconMainView: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
  },
  socialIconSubView: {
    backgroundColor: "rgba(0,0,0,0.3)",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  socialIconView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: social_icon_size + 15,
    height: social_icon_size + 15,
    cursor: "pointer",
    backgroundColor: "#000",
    borderRadius: (social_icon_size + 15) / 2,
    margin: "15px 6px",
  },
  bgImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    position: "absolute",
  },
  iconView: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 5,
  },
  detailBtn: {
    width: SIDEBAR_ICON_SIZE,
    height: SIDEBAR_ICON_SIZE,
    cursor: "pointer",
    marginBottom: `${SIDEBAR_MENU_POSITION}px`,
    lineHeight: 0,
  },
  menuMainView: {
    backgroundColor: "#000",
    // backgroundColor: 'rgba(0,0,0,0.7)',
    height: 52,
    alignItems: "center",
    position: "absolute",
    top: 0,
    zIndex: 4,
  },
  menuTitleView: {
    color: "#FFF",
    fontFamily: "GilroySemiBold",
    fontSize: 18,
    lineHeight: 1.1,
  },
  menuPriceMainView: {
    display: "flex",
    justifyContent: "flex-end",
  },
  menuPriceView: {
    // backgroundColor: THEME_COLOR,
    // borderRadius: 5,
    pl: 1.4,
    py: 0.6,
  },
  menuPriceText: {
    color: THEME_COLOR,
    fontSize: 19,
    fontFamily: "GilroyBold",
  },
};
