import axios from "axios";

let apiUrl = process.env.REACT_APP_APP_API_URL;
let sdkApiUrl = process.env.REACT_APP_SDK_API_URL;

export async function getRestaurant(id) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant/" + id, config);
}

export async function getCategoryList(restaurant_id, filter) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant/" + restaurant_id + "/category" + filter, config);
}

export async function getMenuList(filter) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return callApi("menu" + filter, config);
}

export async function getMenuModel(filter) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.REACT_APP_SDK_MODEL_API_KEY,
    },
  };

  return callSdkApi("model" + filter, config);
}

export async function createOrder(data) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("orders", config, true);
}

export async function addUserEnquiries(data) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-users", config);
}

export async function getTableDetailByNUmber(restaurantId, tableNo) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return callApi(`table/restaurant/${restaurantId}/name/${tableNo}`, config);
}

export async function getCourseList(restaurantId) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return callApi(`/restaurant-course`, config);
}

/**
 * Private functions
 */
async function callApi(endpoint, config, rst_url = false, api_url = "") {
  let url = `${apiUrl}${endpoint}`;
  if (rst_url) {
    url = `${process.env.REACT_APP_RESTAURANT_API_URL}${endpoint}`;
  }
  if (api_url != "") {
    url = `${api_url}${endpoint}`;
  }
  console.log("Url: ", url);
  console.log("Config: ", config);

  return axios({
    ...config,
    url: url,
  })
    .then((response) => {
      console.log("-----API RESPONSE-----", response.data);
      return response.data;
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          return {
            success: false,
            msg: error.response.data.msg,
          };
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}

async function callSdkApi(endpoint, config) {
  const url = `${sdkApiUrl}${endpoint}`;

  console.log("Url: ", url);
  console.log("Config: ", config);

  return axios({
    ...config,
    url: url,
  })
    .then((response) => {
      console.log("-----API RESPONSE-----", response.data);
      return response.data;
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          return {
            success: false,
            msg: error.response.data.msg,
          };
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}
