import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCartData } from "../redux/actions/restaurantDataActions";

const TableContext = createContext();

export const TableProvider = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [currentTableNumber, setCurrentTableNumber] = useState(
        localStorage.getItem("currentTableNumber") || null
    );

    useEffect(() => {
        const urlParts = location.pathname.split("/");
        const tableIndex = urlParts.indexOf("table");
        const newTableNumber = tableIndex !== -1 ? urlParts[tableIndex + 1] : null;

        if (newTableNumber && newTableNumber !== currentTableNumber) {
            setCurrentTableNumber(newTableNumber);
            localStorage.setItem("currentTableNumber", newTableNumber);

            dispatch(clearCartData());
        }
    }, [location.pathname, currentTableNumber, dispatch]);

    return (
        <TableContext.Provider value={{ currentTableNumber }}>
            {children}
        </TableContext.Provider>
    );
};

export const useTableContext = () => {
    return useContext(TableContext);
};
