import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Delivery": "Delivery",
      "Pick Up": "Pick Up",
      "Cart": "Cart",
      "Checkout": "Checkout",
      "Add Address": "Add Address",
      "Address": "Address",
      "Order confirmation": "Order confirmation",
      "Pay Now": "Pay Now",
      "Back to Home": "Back to Home",
      "Items": "Items",
      "Order Summary": "Order Summary",
      "Subtotal": "Subtotal",
      "Shipping": "Shipping",
      "Total": "Total",
      "Billing and delivery addresses are same.": "Billing and delivery addresses are same.",
      "Payment Method": "Payment Method",
      "Payment": "Payment",
      "Add Credit / Debit Card": "Add Credit / Debit Card",
      "Card Holder's Name": "Card Holder’s Name",
      "Card Number": "Card Number",
      "Expire Date": "Expire Date",
      "Expire Month": "Expire Month",
      "Expire Year": "Expire Year",
      "Month": "Month",
      "Year": "Year",
      "Security Code": "Security Code",
      "Remember my card for next purchases.": "Remember my card for next purchases.",
      "Add/Edit": "Add/Edit",
      "Visa Card": "Visa Card",
      "Master Card": "Master Card",
      "American Express": "American Express",
      "Required fields.": "Required fields.",
      "Recipients Information": "Recipients Information",
      "Name and Surname": "Name and Surname",
      "Phone Number": "Phone Number",
      "E-mail Address": "E-mail Address",
      "Shipping Address": "Shipping Address",
      "For shipping related questions only.": "For shipping related questions only.",
      "This address will be used to send you order and bill details.": "This address will be used to send you order and bill details.",
      "Billing Information": "Billing Information",
      "Billing Address": "Billing Address",
      "Same as delivery address.": "Same as delivery address.",
      "Billing Type": "Billing Type",
      "Cancel": "Cancel",
      "Save": "Save",
      "Personal": "Personal",
      "Commercial": "Commercial",
      "Address Title (Optional)": "Address Title (Optional)",
      "For estimating if the place is opened or closed on the weekends.": "For estimating if the place is opened or closed on the weekends.",
      "Street Address 2 (Optional)": "Street Address 2 (Optional)",
      "Pin Code": "Pin Code",
      "City": "City",
      "Country": "Country",
      "Street, apartment name etc.": "Street, apartment name etc.",
      "Thank you!": "Thank you!",
      "Thank You": "Thank You",
      "Time placed": "Time placed",
      "Billing": "Billing",
      "Preparing Your Order": "Preparing Your Order",
      "Order Review": "Order Review",
      "Order Now": "Order Now",
      "Edit Address": "Edit Address",
      "Place Order": "Place Order",
      "Enter your card details": "Enter your card details",
      "Your bill": "Your bill",
      "Your Order": "Your Order",
      "Order no": "Order no",
      "Table": "Table",
      "Discount": "Discount",
      "Tax": "Tax",
      "Tip": "Tip",
      "Split Payment": "Split Payment",
      "Thankyou for your generous tips for future orders.": "Thankyou for your generous tips for future orders.",
      "Delivery Address": "Delivery Address",
      "Add More": "Add More",
      "Continue to Bank Verification": "Continue to Bank Verification",
      "Choose Address": "Choose Address",
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
